@import "plugins/bootstrap/bootstrap";
//@import "../js/plugins/lightbox/lightbox.scss";

@mixin fill{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@mixin anim{
    transition: 0.3s all ease;
}

@mixin bg_pos{
    background-repeat: no-repeat;
    background-position: center;
}

@mixin vertical{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
}

$special_font:'Red Hat Display', sans-serif;
$main_font:'Open Sans', sans-serif;

$accent_color1:#cd1b23;
$accent_color2:#333a49;
$accent_color3:#1c222f;

$theme_color1:#e9e9e9;
$theme_color2:#1c222f;
$theme_color3:#18191d;

$white:#ffffff;
$black:#000000;

$font_color:#787272;

body{
    font-family: $main_font;
    h1,h2,h3,h4,h5,h6,p,ul,ol,a{
        margin: 0;
    }
    ul{
        list-style: none;
        padding: 0;
    }
    a{
        text-decoration: none;
    }
    img{
        max-width: 100%;
        height: auto;
    }
}

.page{
    overflow: hidden;
}

.default_font{
    font-size: 15.5px;
    line-height: 30px;
    @media only screen and (max-width: 991px) {
        font-size: 12px;
        line-height: 28px;
    }
    h1,h2{
        font-size: 40px;
        line-height: 53px;
        @media only screen and (max-width: 1500px) {
            font-size: 30px;
            line-height: 43px;
        }
        @media only screen and (max-width: 991px) {
            font-size: 22px;
            line-height: 35px;
        }
    }
    h1{
        @media only screen and (max-width: 1200px) {
            font-size: 25px;
            line-height: 38px;
        }
    }
    h3{
        font-size: 31px;
        line-height: 41px;
        @media only screen and (max-width: 1500px) {
            font-size: 27px;
            line-height: 37px;
        }
        @media only screen and (max-width: 991px) {
            font-size: 20px;
            line-height: 30px;
        }
    }
    h4{
        font-size: 24px;
        line-height: 34px;
        @media only screen and (max-width: 991px) {
            font-size: 18px;
            line-height: 28px;
        }
    }
    h5{
        font-size: 21px;
        line-height: 31px;
        @media only screen and (max-width: 991px) {
            font-size: 16px;
            line-height: 26px;
        }
    }
    h6{
        font-size: 16px;
        line-height: 25px;
        @media only screen and (max-width: 991px) {
            font-size: 14px;
            line-height: 33px;
        }
    }
    h1,h2,h3,h4,h5,h6,p,ul,ol,p,a{
        margin-bottom: 15px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    ul{
        padding-left: 50px;
        list-style: disc;
    }
    ol{
        padding-left: 50px;
    }
    &.desc_box{
        h2{
            font-size: 23px;
            line-height: 33px;
        }
        h3{
            font-size: 20px;
            line-height: 30px;
        }
        h4{
            font-size: 18px;
            line-height: 28px;
        }
        h5{
            font-size: 16px;
            line-height: 26px;
        }
        h6{
            font-size: 14.5px;
            line-height: 24.5px;
        }
    }
    a{
        color: $accent_color1;
        &:hover{
            text-decoration: none;
        }
    }
    table{
        border-collapse: collapse;
        -webkit-box-shadow: 0px 0px 40px 0px rgba(48,41,31,0.2);
        -moz-box-shadow: 0px 0px 40px 0px rgba(48,41,31,0.2);
        box-shadow: 0px 0px 40px 0px rgba(48,41,31,0.2);
        th,td{
            padding: 10px;
        }
        thead{
            background: $accent_color1;
            th{
                color: $white;
            }
        }
        tbody{
            color:#5d6472;
            tr{
                border-bottom: 2px solid #e3e6e9;
                &:nth-child(odd){
                    background:$white;
                }
                &:nth-child(even){
                    background:#f4f4f4;
                }
            }
        }
    }
    .link0{
        color: $black;
        margin-right: 15px;
    }
}

.page_header{
    .fast_links{
        > ul{
            > li{
                > div{
                    .more_toggle{
                        position: absolute !important;
                        top: 0;
                        right: 0;
                        width: 30px;
                        height:23px;
                        &.opened{
                            div{
                                top:-10px;
                                transform: rotate(90deg);
                            }
                        }
                        > div{
                            @include fill;
                            background-position: right top;
                            background-repeat: no-repeat;
                            background-image: url("./../img/sl_arr_right.png");

                        }
                    }
                }
            }
        }
    }
}

.page_header{
    position: relative;
    z-index: 99;
    &.submenu{
        .hovered{
            @include anim;
            background: #1c222f;
            //position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            margin-top: -35px;
            padding-top: 35px;
            //visibility: hidden;
            //opacity: 0;
            &.open{
                margin-top: -35px;
                //opacity: 1;
                //visibility: visible;
            }
            nav{
                //padding: 0 25px;
                @media only screen and (min-width: 991px) {
                    > ul{
                        > li{
                            position: relative;
                            z-index: 2;
                            > div{
                                &.on{
                                    a{
                                        color:$accent_color1;;
                                    }
                                }
                            }
                            &:hover{
                                > ul{
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                            > ul {
                                @include anim;
                                opacity: 0;
                                visibility: hidden;
                                position: absolute;
                                left: 0;
                                top: 100%;
                                width: 300px;
                                background: #d9d9d9;
                                padding-top: 5px;
                                padding-bottom: 10px;
                                > li{
                                    width: 100%;
                                    @include anim;
                                    &:hover{
                                        background: $accent_color1;
                                        div{
                                            a{
                                                color: $white;
                                            }
                                        }
                                    }
                                    > div{
                                        &.on{
                                            //background: $accent_color1;
                                            a{
                                                color: $white;
                                                &:after{
                                                    width: 100%;
                                                    left: 0;
                                                }
                                            }
                                        }
                                        a{
                                            @include  anim;
                                            color: $accent_color3;
                                            line-height: 35px;

                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                ul{
                    li{
                        display: inline-block;
                        vertical-align: top;
                        /*&:last-child{
                            div{
                                a{
                                    &:after{
                                        display: none;
                                    }
                                }
                            }
                        }*/
                        div{
                            a{
                                font-family: $special_font;
                                text-transform: uppercase;
                                color: #9da2ac;
                                font-weight: 700;
                                font-size: 14.5px;
                                line-height: 70px;
                                display: block;
                                padding: 0 15px;
                                position: relative;
                                /* @media only screen and (max-width: 1500px) {
                                     padding: 0 25px;
                                     font-size: 12.5px;
                                     line-height: 55px;
                                 }*/
                                @media only screen and (max-width: 1500px) {
                                    font-size: 11.5px;
                                }
                                @media only screen and (max-width: 1200px) {
                                    padding: 0 10px;
                                    font-size: 10px;
                                }
                                &:hover{
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    //background: #fdfdfd;
    background: rgba(255,255,255,1);
    background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(239,239,240,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(100%, rgba(239,239,240,1)));
    background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(239,239,240,1) 100%);
    background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(239,239,240,1) 100%);
    background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(239,239,240,1) 100%);
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(239,239,240,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#efeff0', GradientType=0 );
    div{
        &.header_top{
            padding: 15px 0;
            div{
                &.logo_box{
                    position: relative;
                    @media only screen and (max-width: 991px) {
                        img{
                            width: 200px;
                        }
                        div.rwd_btn{
                            width: 30px;
                            position: absolute;
                            right: 0;
                            top: 15px;
                            z-index: 10;
                            > div{
                                width: 100%;
                                height: 3px;
                                margin-bottom: 3px;
                                background: $accent_color1;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
                &.fast_links{
                    text-align: center;
                    padding-top: 34px;
                    width: calc(100% + 30px);
                    margin-left: -15px;
                    @media only screen and (max-width: 1500px) {
                        padding-top: 23px;
                    }
                    @media only screen and (max-width: 1200px) {
                        padding-top: 17px;
                    }
                    @media only screen and (max-width: 991px) {
                        text-align: left;
                        padding:10px 15px 0 15px;
                    }
                    ul{
                        li{
                            display: inline-block;
                            vertical-align: top;
                            margin-right: 25px;
                            @media only screen and (max-width: 1500px) {
                                margin-right: 18px;
                            }
                            @media only screen and (max-width: 991px) {
                                margin-right: 0;
                                width: 100%;
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                            div{
                                position: relative;
                                &:hover{
                                    ul{
                                        opacity: 1;
                                        visibility: visible;
                                        text-align: left;
                                    }
                                }
                                ul{
                                    position: absolute;
                                    @include anim;
                                    opacity: 0;
                                    visibility: hidden;
                                    background: $white;
                                    padding-top: 10px;
                                    -webkit-box-shadow: 0px 0px 16px 0px rgba(48, 41, 31, 0.2);
                                    -moz-box-shadow: 0px 0px 16px 0px rgba(48, 41, 31, 0.2);
                                    box-shadow: 0px 0px 16px 0px rgba(48, 41, 31, 0.2);
                                    white-space: nowrap;
                                    z-index: 10;
                                    min-width: 100%;
                                    @media only screen and (max-width: 991px) {
                                        position: relative;
                                        visibility: visible;
                                        opacity: 1;
                                        padding-top: 0;
                                        background:transparent;
                                        box-shadow: none;
                                        display: none;
                                        transition: none;
                                    }

                                li{
                                    display: block;
                                        width: 100%;
                                        margin-right: 0;
                                        padding:0 10px 10px 10px;
                                        border-bottom: 1px solid #ccd0d1;
                                        margin-bottom: 10px;
                                        @media only screen and (max-width: 991px) {
                                            margin-bottom: 0;
                                            padding: 0 0 0 10px;
                                            border-bottom:none;
                                            margin-bottom: 0;
                                        }
                                        &:last-child{
                                            margin-bottom: 0;
                                            border-bottom: none;
                                        }
                                    }
                                }
                                &.on{
                                    a{
                                        color: $accent_color1;
                                    }
                                }
                                a{
                                    text-transform: uppercase;
                                    font-size: 11px;
                                    line-height: 11px;
                                    color: $black;
                                    text-decoration: none;
                                    font-weight: 600;
                                    @include  anim;
                                    font-family: $special_font;
                                    @media only screen and (max-width: 1500px) {
                                        font-size: 10px;
                                        line-height: 10px;
                                    }
                                    @media only screen and (max-width: 1200px) {
                                        font-size: 8px;
                                        line-height: 8px;
                                    }
                                    @media only screen and (max-width: 991px) {
                                        font-size: 13px;
                                        line-height: 23px;
                                    }
                                    &:hover{
                                        color: $accent_color1;
                                    }
                                }
                            }
                        }
                    }
                }
                &.search_availability{
                    padding-top: 21px;
                    @media only screen and (max-width: 1500px) {
                        padding-top: 14px;
                    }
                    @media only screen and (max-width: 1200px) {
                        padding-top: 7px;
                    }
                    ul{
                        li{
                            display: inline-block;
                            vertical-align: middle;
                            img{
                                cursor: pointer;
                            }
                            &.search{
                                width: calc(100% - 130px);
                                -webkit-box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
                                -moz-box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
                                box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
                                @media only screen and (max-width: 1200px) {
                                    width: calc(100% - 110px);
                                }
                                @media only screen and (max-width: 991px) {
                                    width: 100%;
                                    position: relative;
                                    z-index: 10;
                                }
                                ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                                    color: #5f5e5e;
                                }
                                ::-moz-placeholder { /* Firefox 19+ */
                                    color: #5f5e5e;
                                }
                                :-ms-input-placeholder { /* IE 10+ */
                                    color: #5f5e5e;
                                }
                                :-moz-placeholder { /* Firefox 18- */
                                    color: #5f5e5e;
                                }
                                input{
                                    display: inline-block;
                                    vertical-align: top;
                                    border: none;
                                    outline: none;
                                    &.search_input{
                                        height: 47px;
                                        width: calc(100% - 47px);
                                        padding: 0 20px;
                                        color: #5f5e5e;
                                        font-size: 10px;
                                        font-weight: 500;
                                        font-family: $special_font;
                                        text-transform: uppercase;
                                        @media only screen and (max-width: 1500px) {
                                            height: 40px;
                                            width: calc(100% - 40px);
                                        }
                                        @media only screen and (max-width: 1200px) {
                                            padding-right: 0;
                                        }
                                        @media only screen and (max-width: 991px) {
                                            font-size: 9px;
                                            padding: 0 10px;
                                        }
                                    }
                                    &.search_submit{
                                        height: 47px;
                                        width: 47px;
                                        @include bg_pos;
                                        background-image: url('./../img/search_icon.png');
                                        text-indent: 99px;
                                        @media only screen and (max-width: 1500px) {
                                            height: 40px;
                                            width: 40px;
                                        }
                                    }
                                }
                            }
                            &.availability{
                                width: 130px;
                                text-align: right;
                                @media only screen and (max-width: 1200px) {
                                    width: 110px;
                                }
                                @media only screen and (max-width: 991px) {
                                    display: none;
                                }
                                .fonts{
                                    padding-top: 6px;
                                    span{
                                        color: #b1b1b1;
                                        cursor: pointer;
                                        &.font_minus{
                                            font-size: 11px;
                                            margin-right: -2px;
                                        }
                                        &.font_plus{
                                            font-size: 18px;
                                        }
                                    }
                                }
                                ul{
                                    li{
                                        margin-right: 15px;
                                        vertical-align: middle;
                                        &:last-child{
                                            margin-right: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.header_bottom{
            position: relative;
            z-index: 2;
            /*.hovered{
                @include anim;
                background: #1c222f;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                padding-top: 35px;
                visibility: hidden;
                opacity: 0;
                &.open{
                    top: 35px;
                    opacity: 1;
                    visibility: visible;
                }
                nav{
                    padding: 0 25px;
                    ul{
                        li{
                            display: inline-block;
                            vertical-align: top;
                            &:last-child{
                                div{
                                    a{
                                        &:after{
                                            display: none;
                                        }
                                    }
                                }
                            }
                            div{
                                a{
                                    font-family: $special_font;
                                    text-transform: uppercase;
                                    color: #9da2ac;
                                    font-weight: 700;
                                    font-size: 12.5px;
                                    line-height: 70px;
                                    display: block;
                                    padding: 0 30px;
                                    position: relative;
                                    &:after{
                                        content: '|';
                                        height: 33.33%;
                                        top: 0;
                                        right: -1px;
                                        position: absolute;
                                        color: #4e5562;
                                        @include anim;
                                    }
                                    &:hover{
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }*/
            div.flex_box{
                display: flex;
                flex-flow: row;
                flex-wrap: nowrap;
                height: 70px;
                position: relative;
                z-index: 3;
                @media only screen and (max-width: 1500px) {
                    height: 55px;
                }
                div{
                    &.menu_box{
                        background: $accent_color1;
                        width: calc(100% - 235px);
                        padding: 0 20px;
                        nav.menu{
                            /*display: -ms-flexbox;
                            display: -webkit-flex;
                            display: flex;

                            -ms-flex-align: center;
                            -webkit-align-items: center;
                            -webkit-box-align: center;

                            align-items: center;*/
                            @media only screen and (min-width: 991px) {
                                height: 100%;
                                width: 100%;
                            }
                            @media only screen and (max-width: 1500px) {
                                > ul {
                                    > li{
                                        > div{
                                            > a{
                                                font-size:11.5px !important;
                                            }
                                        }
                                    }
                                }
                            }
                            @media only screen and (max-width: 1200px) {
                                > ul {
                                    > li{
                                        > div{
                                            > a{
                                                font-size:9px !important;
                                            }
                                        }
                                    }
                                }
                            }
                            //height: 100%;
                            > ul{
                                width: 100%;
                                @media only screen and (min-width: 991px) {
                                    height: 100%;
                                    width: 100%;
                                    display: table;
                                }
                                > li{
                                    position: relative;
                                    @media only screen and (min-width: 991px) {
                                        display: table-cell !important;
                                        vertical-align: middle !important;
                                        > div{
                                            > a{
                                                display: block !important;
                                                text-align: center;
                                            }
                                        }
                                    }
                                    > ul{
                                        display: none;
                                    }
                                }
                            }
                            ul{
                                li{
                                    display: inline-block;
                                    //vertical-align: top;
                                    vertical-align: middle;
                                    /*&:last-child{
                                        div{
                                            a{
                                                &:after{
                                                    display: none;
                                                }
                                            }
                                        }
                                    }*/
                                    div{
                                        &.on{
                                            a{
                                                text-decoration: none;
                                               // background: #ae1219;
                                               /* &:before{
                                                    opacity: 1;
                                                    visibility: visible;
                                                }
                                                &:after{
                                                    color: #ae1219;
                                                }*/
                                                &:after{
                                                    left: 0;
                                                    width: 100%;
                                                }
                                            }
                                        }
                                        a{
                                            font-family: $special_font;
                                            text-transform: uppercase;
                                            color: $white;
                                            font-weight: 700;
                                            font-size: 13.5px;
                                            //line-height: 70px;
                                            padding: 0 20px;
                                            @include anim;
                                            text-decoration: none;
                                            position: relative;
                                            display: none;
                                           /* @media only screen and (max-width: 1500px) {
                                                padding: 0 25px;
                                                font-size: 12.5px;
                                                line-height: 55px;
                                            }
                                            @media only screen and (max-width: 1200px) {
                                                padding: 0 15px;
                                                font-size: 10.5px;
                                            }*/
                                            /*&:before{
                                                @include anim;
                                                position: absolute;
                                                left: 0;
                                                top: 100%;
                                                content: '';
                                                width: 100%;
                                                height: 6px;
                                                background-repeat: no-repeat;
                                                background-position: center top;
                                                background-image: url('./../img/menu_arr.png');
                                                opacity: 0;
                                                visibility: hidden;
                                            }
                                            &:after{
                                                content: '|';
                                                height: 33.33%;
                                                top: 0;
                                                right: -1px;
                                                position: absolute;
                                                color: #330203;
                                                @include anim;
                                            }*/
                                            /*&:hover{
                                                text-decoration: none;
                                                background: #ae1219;
                                                &:before{
                                                    opacity: 1;
                                                    visibility: visible;
                                                }
                                                &:after{
                                                    color: #ae1219;
                                                }
                                            }*/
                                            &:after{
                                                content: '';
                                                position: absolute;
                                                left: 50%;
                                                width: 0;
                                                @include anim;
                                                border-bottom: 2px solid $white;
                                                top: 100%;
                                            }
                                            &:hover{
                                                background: transparent !important;
                                                &:after{
                                                    width: 100%;
                                                    left: 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.social_box{
                        background: $white;
                        width: 235px;
                        @include vertical;
                        > ul{
                            width: 100%;
                            text-align: center;
                            > li{
                                display: inline-block;
                                vertical-align: middle;
                                margin-right: 23px;
                                position: relative;
                                &:hover{
                                    ul{
                                        margin-top: 0;
                                        opacity: 1;
                                        visibility: visible;
                                    }
                                }
                                &.instagrams{
                                    ul{
                                        li{
                                            background-image: url("./../img/instagram_ikona.svg");
                                        }
                                    }
                                }
                                &.twitters{
                                    ul{
                                        li{
                                            background-image: url("./../img/twitter_ikona.svg");
                                        }
                                    }
                                }
                                &.youtubes{
                                    ul{
                                        li{
                                            background-image: url("./../img/youtube_ikona.svg");
                                        }
                                    }
                                }
                                &.facebooks{
                                    ul{
                                        li{
                                            background-image: url("./../img/facebook_ikona.svg");
                                        }
                                    }
                                }
                                &:last-child{
                                    margin-right: 0;
                                }
                                ul{
                                    margin-top:20px;
                                    position: absolute;
                                    top: calc(100% + 10px);
                                    background: #f1f1f2;
                                    width: 155px;
                                    left: -67px;
                                    text-align: left;
                                    padding: 20px;
                                    @include anim;
                                    opacity: 0;
                                    visibility: hidden;
                                    &:before{
                                        content: '';
                                        background-position: center bottom;
                                        background-repeat: no-repeat;
                                        background-image: url("./../img/hover_arr.png");
                                        width: 100%;
                                        height: 10px;
                                        position: absolute;
                                        bottom: 100%;
                                        left: 0;
                                    }
                                    li{
                                        padding-left: 30px;
                                        background-repeat: no-repeat;
                                        background-position:left center;
                                        min-height: 25px;
                                        margin-bottom: 12px;
                                        &:last-child{
                                            margin-bottom: 0;
                                        }
                                        a{
                                            font-family: $special_font;
                                            color: $black;
                                            font-weight: 900;
                                            text-transform: uppercase;
                                            font-size: 9.3px;
                                            @include anim;
                                            &:hover{
                                                padding-left: 5px;
                                                text-decoration: none;
                                                color: $accent_color1;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.select_holder{
    @media only screen and (max-width: 991px) {
        margin-bottom: 15px;
        select{
            width: 100%;
        }
    }
}

.more_btn{
    display: inline-block;
    font-family: $special_font;
    font-size: 12.5px;
    line-height: 12.5px;
    padding-right: 45px;
    background-position: right center;
    background-repeat: no-repeat;
    //background-image: url("./../img/more_arr.png");
    color: $white;
    font-weight: 700;
    @include anim;
    &:hover{
        color: $accent_color1;
        text-decoration: none;
    }
}

.more_btn2{
    display: inline-block;
    padding: 0 44px;
    text-align: center;
    height: 55px;
    line-height: 55px;
    color: $white;
    background: $accent_color1;
    font-size: 12.5px;
    font-family: $special_font;
    @include anim;
    &:hover{
        color: $accent_color1;
        background: $white;
        text-decoration: none;
        span{
            padding-right: 0px;
        }
    }
    span{
        font-weight: 700;
        padding-right: 35px;
        background-repeat: no-repeat;
        background-position: right center;
        background-image: url('./../img/more_arr_white.png');
        @include anim;
    }
}

.slider{
    position: relative;
    width: 100%;
    background: $black;
    @media only screen and (max-width: 991px) {
        height: 350px !important;
        margin-top: 0 !important;
    }
    .category{
        p{
            margin-left: 10px;
            margin-bottom: 20vw;
            @media only screen and (max-width: 991px) {
                margin-bottom: 15px;
            }
        }
    }
    > ul{
        @include fill;
        > li{
            @include fill;
            .slide_link{
                @include fill;
                z-index: 2;
            }
            div.image{
                @include fill;
                @include  bg_pos;
                background-size: cover;
            }
            div.slider_content{
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 0;
                height: 75%;
                z-index: 2;
                .desc{
                    padding-right: 235px;
                    @media only screen and (max-width: 991px) {
                        padding-right: 0;
                    }
                }
                @media only screen and (max-width: 1500px) {
                    height: 73%;
                }
                @media only screen and (max-width: 1200px) {
                    height: 85%;
                }
                @media only screen and (max-width: 991px) {
                    height: auto;
                }
                header{
                    margin-bottom: 40px;
                    @media only screen and (max-width: 991px) {
                        margin-bottom: 20px;
                    }
                    .default_font{
                        font-family: $special_font;
                        padding-left: 10px;
                        h1{
                            padding: 0 5px;
                            display:inline;
                            color: $white;
                            background: rgba(0,0,0,0.5);
                           // background: rgba(255, 0, 0, 0.5);
                            box-shadow: 10px 0 0 rgba(0,0,0,0.5), -10px 0 0 rgba(0,0,0,0.5);
                        }
                    }
                }
                .short_desc{
                    color: $white;
                    margin-bottom: 20px;
                    .default_font{
                        padding-left: 13px;
                        p{
                            background: rgba(0,0,0,0.5);
                            box-shadow: 10px 0 0 rgba(0,0,0,0.5), -10px 0 0 rgba(0,0,0,0.5);
                            display: block;
                        }
                    }
                    @media only screen and (max-width: 991px) {
                        display: none;
                    }
                }
                .more_btn2{
                    font-size: 14px;
                }
            }
        }
    }
    .slider_nav{
        .desctop_arr{
            @media only screen and (max-width: 991px) {
                display: none;
            }
        }
        .rwd_arr{
            display: none;
            @media only screen and (max-width: 991px) {
                display: block;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background-color:$white;
                background-repeat:no-repeat;
                background-position: center;

            }
            &.rwd_arr_left{
                background-image:url('./../img/sl_arr_left.png');
                margin-right: auto;
            }
            &.rwd_arr_right{
                background-image:url('./../img/sl_arr_right.png');
                margin-left: auto;
            }
        }
        @include fill;
        @include vertical;
        @media only screen and (max-width: 991px) {

        }
        > div{
            width: 100%;
        }
        div{
            &.arr{
                @media only screen and (max-width: 991px) {
                    display: inline-block;
                    vertical-align: top;
                    width: 50%;
                    height: auto;
                    background-color: transparent;
                }
                margin-left: auto;
                width: 60px;
                height:  60px;
                line-height: 60px;
                text-align: center;
                border-radius: 50%;
                background: $white;
                display: block;
                cursor: pointer;
                z-index: 3;
                position: relative;
                &.arr_left{
                    margin-bottom: 20px;
                }
                @media only screen and (max-width: 991px) {
                    &.arr_left{
                        margin-bottom: 20px;
                        text-align:left;
                    }
                    &.arr_right{
                        text-align:right;
                    }
                }
            }
        }
    }
}

.category{
    p{
        display: inline-block;
        padding: 0 13px;
        min-height: 20px;
        line-height: 20px;
        font-size:9px;
        text-transform: uppercase;
        background: $accent_color1;
        color: $white;
        font-weight: 900;
        margin-bottom: 40px;
    }
}

main.page_content{
    background: $theme_color1;
}

.main_sponsors{
    padding: 80px 0;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0;
    }
    @media only screen and (max-width: 991px) {
        display: none;
    }
    .row{
        margin: 0 -25px;
        > div{
            padding: 0 25px;
        }
    }
    .image{
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        @include  anim;
        @include bg_pos;
        background-size: contain;
        background-color:rgba(255,255,255,0.8);
        position:relative;
        iframe{
            @include fill;
        }
        @media only screen and (max-width: 991px) {
            margin-bottom: 20px;
        }
        &:hover{
            transform: scale(1.05);
            -webkit-box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
            -moz-box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
            box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
        }
    }
}

.section_header{
    font-family: $special_font;
    margin-bottom: 30px;
    @media only screen and (max-width: 991px) {
        margin-bottom: 30px;
    }
    .date{
        color: #9da2ac;
        font-size: 14.5px;
        font-weight: 700;
        font-family: $special_font;
        margin-bottom: 10px;
        p{
            font-weight: 700;
        }
    }
    div.default_font{
        color: #7e8594;
        font-family: $special_font;
        h1,h2,h3,h4,h5,h6,p{
            margin-bottom: 0px;
        }
        h1,h2,h3,h4,h5,h6{
            color: $black;
        }
        a{
            background-color: $white;
            border: 1px solid #eaeaea;
            border-bottom: 3px solid #eaeaea;
            padding: 0 70px 0 20px;
            background-repeat: no-repeat;
            transition: 0.3s all ease;
            display: inline-block;
            margin: 10px 0;
            color: $black;
            height: 60px;
            line-height: 60px;
            font-family: $special_font;
            background-repeat: no-repeat;
            background-position: right 30px center;
            background-image: url(./../img/more_arr.png);
            font-weight: 500;
            @include anim;
            &:hover{
                color: $white;
                background-color: $accent_color1;
                text-decoration: none;
                background-image: url(./../img/more_arr_white.png);
            }
        }
    }
    &.splited{
        position: relative;
        width: 100%;
        margin-bottom: 35px;
        &:after{
            content: '';
            border-bottom: 2px solid #ccd0d1;
            position: absolute;
            //left: 0;
            right: 0;
            top: 33%;
            //width: 100%;
            width: calc(100% - 273px);
            @media only screen and (max-width: 1500px) {
                width: calc(100% - 230px);
            }
            @media only screen and (max-width: 991px) {
                display: none;
            }
        }
        > div{
            display: inline-block;
            vertical-align: top;
            &.default_font{
                width: calc(100% - 400px);
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    text-align: center;
                }
                > div{
                    display: inline-block;
                    padding-right: 40px;
                    @media only screen and (max-width: 991px) {
                        padding-right: 0;
                    }
                }
            }
            &.filters{
                text-align: right;
                width: 400px;
                padding-top: 15px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    text-align: center;
                }
                ul{
                    li{
                        display: inline-block;
                        vertical-align: top;
                        &.filter_desc_holder{
                            width: 190px;
                            padding-left: 40px;
                            @media only screen and (max-width: 991px) {
                                padding-left: 0;
                                width: 100%;
                            }
                        }
                        &.select_holder{
                            width: calc(100% - 190px);
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                            }
                            li{
                                width: 100%;
                            }
                        }
                        &:last-child{
                            padding-top: 6px;
                        }
                        p{
                            color: $black;
                            font-weight: 500;
                            padding-right: 15px;
                            @media only screen and (max-width: 991px) {
                                padding-right: 0;
                            }
                        }
                        &:last-child{
                            width: 200px;
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                            }
                        }
                        .chosen-container-single {
                            text-align: left;
                            &.chosen-container-active{
                                .chosen-single{

                                }
                            }
                            .chosen-single {
                                border-radius: 0;
                                background: transparent;
                                border: none;
                                box-shadow: none;
                                border-bottom: 1px solid #7b6d6d;
                                padding: 0;
                                font-family: $special_font;
                                color: $accent_color1;
                                font-weight: 700;
                                div{
                                    b{
                                        background-repeat: no-repeat;
                                        background-position: center right;
                                        background-image: url("./../img/select_arr.png");
                                    }
                                }
                            }
                            .chosen-drop{
                                background: $theme_color1;
                                box-shadow: none;
                                .active-result{
                                    font-size: 13px;
                                    font-family: $special_font;
                                    &.highlighted{
                                        background: $accent_color1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .cover{
            //background: $theme_color1;
            //background: #e9e9e9;
            position: relative;
            z-index: 2;
            @media only screen and (max-width: 991px) {
                background: transparent !important;
            }
        }
    }
}

.events{
    margin-bottom: 50px;
    @media only screen and (max-width: 991px) {
        margin-bottom: 30px;
        padding-top: 50px;
    }
    .section_header{
        .cover{
            background: #eeefee;
        }
    }
    .event_calendar{
        margin-bottom: 40px;
        @media only screen and (max-width: 1200px) {

            overflow-x: scroll;
            .mycalendar2_slider, .pioromycalendar2_js_ajax_box_event_calendar{
                width: 1000px;
            }
        }
    }
    .events_list{
        ul{
            margin: 0 -15px;
            li{
                display: inline-block;
                vertical-align: top;
                width: 33.33%;
                padding: 0 15px;
                margin-bottom: 30px;
                .image{
                    @include fill;
                    @include bg_pos;
                    @include anim;
                    background-size: cover;
                }
                .content{
                    @include fill;
                    z-index: 2;
                }
                &.large{
                    article{
                        width: 100%;
                        height: 0;
                        padding-bottom: 100%;
                        position: relative;
                        overflow: hidden;
                        &:hover{
                            .content{
                                .desc{
                                    opacity: 0;
                                    visibility: hidden;
                                }
                            }
                            .image{
                                transform: scale(1.1);
                            }
                            .cover{
                                &:before{
                                    left: -100%;
                                }
                                &:after{
                                    right: -100%;
                                }
                            }
                        }
                        .cover{
                            @include fill;
                            transform: rotate(90deg);
                            &:before, &:after{
                                content: '';
                                height: 100%;
                                width: calc(50% + 0.30px);
                                @include anim;
                                position: absolute;
                                top: 0;
                                background: rgba(0,0,0,0.5);
                            }
                            &:before{
                                left: 0;
                            }
                            &:after{
                                right: 0;
                            }
                        }
                        .content{
                            .category{
                                margin: 20px 0 0 20px;
                            }
                            .desc{
                                position: absolute;
                                width: 100%;
                                left: 0;
                                bottom: 20px;
                                font-family: $special_font;
                                color: $white;
                                padding: 20px;
                                @include  anim;
                                opacity: 1;
                                visibility: visible;
                                header{
                                    margin-bottom: 55px;
                                    padding-right: 5%;
                                    h3{
                                        font-size: 16px;
                                        line-height: 27px;
                                        font-weight: 700;
                                    }
                                }
                                .date{
                                    font-size: 11.5px;
                                    line-height: 13px;
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .competitions_list{
        padding-top: 30px;
        @media only screen and (max-width: 1500px) {
            padding-top: 0;
        }
        ul{
            margin: 0 -15px;
            li{
                padding: 0 15px;
                display: inline-block;
                vertical-align: top;
                width: 33%;
                @media only screen and (max-width: 1500px) {
                    width: 33%;
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-bottom: 15px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                a{
                    article{
                        height: 270px;
                        width: 100%;
                        position: relative;
                        @include vertical;
                        @media only screen and (max-width: 1500px) {
                            height: 240px;
                        }
                        @media only screen and (max-width: 1200px) {
                            height: 200px;
                        }
                        @media only screen and (max-width: 991px) {
                            //height: 45vw;
                            height: auto;
                            display: block;
                        }
                        .image{
                            @include fill;
                            @include bg_pos;
                            background-size: cover;
                            @media only screen and (max-width: 991px) {
                                position: relative;
                                height: auto;
                                width: auto;
                                background-image: none;
                            }
                        }
                        .desc{
                            width: 100%;
                            text-align: center;
                            position: relative;
                            z-index: 2;
                            p{
                                font-size: 16px;
                                display: inline-block;
                                padding: 12px 23px;
                                background: $accent_color1;
                                color: $white;
                                font-family: $special_font;
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
        }
    }
}

.categories{
    background: $accent_color2;
    margin-bottom: 30px;
    ul{
        height: 55px;
        display: flex;
        flex-wrap: nowrap;
        flex-flow: row;
        @media only screen and (max-width: 991px) {
            display: block;
            height: auto;
        }
        li{
            width: calc(100% / 7);
            @include vertical;
            padding-left: 20px;

            border-right: 1px solid #2b313e;
            @include anim;
            font-size: 13.5px;
            text-transform: uppercase;
            color: $white;
            font-family: $special_font;
            font-weight: 700;
            cursor: pointer;
            position: relative;
            div.icon{
                position: absolute;
                right: 20px;
                top: 0;
                height: 100%;
                width: 55px;
                @include  anim;
                @media only screen and (max-width: 1500px) {
                    right: 7px;
                }
                @media only screen and (max-width: 1200px) {
                    right: 2px;
                }
                @media only screen and (max-width: 991px) {
                    height: 50px;
                }
                > div{
                    @include fill;
                    background-repeat: no-repeat;
                    background-position: center;
                    @include  anim;
                    &.icon_unhover{
                        opacity: 1;
                    }
                    &.icon_hover{
                        opacity: 0;
                    }
                }
            }
            @media only screen and (max-width: 1500px) {
                padding-left: 17px;
                font-size: 12px;
            }
            @media only screen and (max-width: 1200px) {
                font-size: 10px;
                padding-left: 10px;
            }
            @media only screen and (max-width: 991px) {
                width: 100% !important;
                height: 50px;
            }
            p{
                font-weight: inherit;
                display: inline-block;
                position: relative;
                &:after{
                    content: '';
                    border-bottom: 2px solid $accent_color1;
                    width: 0;
                    left: 50%;
                    position: absolute;
                    top: 100%;
                    @include anim;
                }
            }
            &:hover{
                background-color: $white;
                color: $accent_color1;
                border-right: 1px solid $white;
                p{
                    &:after{
                        width: 100%;
                        left: 0;
                    }
                }
                div.icon{
                    > div{
                        &.icon_unhover{
                            opacity: 0;
                        }
                        &.icon_hover{
                            opacity: 1;
                        }
                    }
                }
            }
            &.active{
                background-color: $white;
                color: $accent_color1;
                border-right: 1px solid $white;
                p{
                    &:after{
                        width: 100%;
                        left: 0;
                    }
                }
            }
            &:last-child{
                border-right: none;
            }
        }
    }
}

.grid_list{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 3fr));
    grid-gap: 25px;
    grid-auto-rows: minmax(250px, auto);
    grid-auto-flow: dense;
    @media only screen and (max-width: 1200px) {
        grid-template-columns: repeat(auto-fill, minmax(40%, 3fr));
    }
    @media only screen and (max-width: 991px) {
        grid-template-columns: repeat(auto-fill, minmax(100%, 3fr));
    }
    .grid_item{
        padding: 1rem;
        font-size: 14px;
        font-weight: bold;
        color: #929796;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        @include  anim;
        &:hover{
            transform: scale(1.05);
        }
        article{
            @include fill;

        }
        &.image_full{
            grid-column-end: span 1;
            grid-row-end: span 2;
            .image{
                @include fill;
                .desc_holder{
                    -ms-flex-align: flex-end;
                    -webkit-align-items: flex-end;
                    -webkit-box-align: flex-end;
                    .desc{
                        color:$white;
                        padding: 32px 40px;
                        @media only screen and (max-width: 991px) {
                            padding: 20px;
                        }
                        div{
                            &.news_category{
                                p{
                                    border-bottom: 1px solid $white;
                                }
                            }
                            &.news_bottom{
                                border-top: 1px solid rgba(255,255,255,0.3);
                                margin-top: 13px;
                                .more_btn{
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.image_half{
            grid-column-end: span 1;
            grid-row-end: span 2;
            .image{
                position: absolute;
                left: 0;
                top: 0;
                height: 50%;
                width: 100%;
            }
            .desc_holder{
                position: absolute;
                left: 0;
                top: 50%;
                height: 50%;
                width: 100%;
                background: $white;
            }
        }

        &.image {
            grid-column-end: span 1;
            grid-row-end: span 1;
        }
        &.no_image{
            grid-column-end: span 1;
            grid-row-end: span 1;
            background: $white;
        }
        &.image_small{
            grid-column-end: span 1;
            grid-row-end: span 1;
            .image{
                @include fill;
                .desc_holder{
                    .desc{
                        color:$white;
                        div{
                            &.news_category{
                                p{
                                    border-bottom: 1px solid $white;
                                }
                            }
                            &.news_bottom{
                                border-top: 1px solid rgba(255,255,255,0.3);
                                margin-top: 13px;
                                .more_btn{
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }
        }
        .image{
            @include bg_pos;
            background-size: cover;
        }
        .cover{
            @include fill;
            background-position: center bottom;
            background-size: cover;
            background-image: url('./../img/news_cover.png');
            opacity: 0.8;
        }
        .desc_holder{
            @include fill;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;

            z-index: 2;

            -ms-flex-align: center;
            -webkit-align-items: center;
            -webkit-box-align: center;
            .desc{
                padding: 40px;
                width: 100%;
                color: $black;
                @media only screen and (max-width: 991px) {
                    padding: 20px;
                }
                div{
                    &.news_category{
                        margin-bottom: 15px;
                        p{
                            font-family: $special_font;
                            font-weight: 900;
                            text-transform: uppercase;
                            display: inline-block;
                            font-size: 10px;
                            padding-left: 0;
                            padding-right: 0;
                            background: transparent;
                            border-bottom: 1px solid $black;
                        }
                    }
                    &.news_title{
                        height: 108px;
                        h3{
                            font-weight: 700;
                            font-family: $special_font;
                            font-size: 24px;
                            line-height: 36px;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                    &.news_bottom{
                        padding-top: 21px;
                        margin-top: 21px;
                        border-top: 1px solid $black;
                        .date{
                            font-size: 11px;
                            font-weight: 700;
                            font-family: $special_font;
                        }
                        .more_btn{
                            font-size: 11px;
                            background-image: url('./../img/more_arr2.png');
                            padding-right: 35px;
                            color: $black;
                        }
                    }
                }
            }
        }
    }
}

.calendar_details_table{
    display: table;
    width: 100%;
    color: #5d6472;
    font-size: 12.5px;
    border-collapse: collapse;
    a{
        color:#5d6472;
    }
    p{
        font-weight: 600;
    }
    .calendar_details_row{
        display: table-row;
        width: 100%;
        border-bottom: 2px solid #e3e6e9;
        &:nth-child(odd){
            background: #f4f4f4;
        }
        &:nth-child(even){
            background: $white;

        }
        &.calendar_details_header{
            background: $accent_color1;
            color: $white;
            .calendar_details_cell{
                height: 55px;
            }
        }
        .calendar_details_cell{
            display: table-cell;
            vertical-align: middle;
            height: 100%;
            padding: 0 20px;
            height: 70px;
            cursor: pointer;
            &.w1{
                width: 100px;
            }
            &.w2{
                width: 200px;
            }
            &.w3{
                width: 200px;
            }
            &.w4{
                width: auto;
            }
            .status{
                width: 40px;
                div{
                    display: inline-block;
                    vertical-align: top;
                    color: #dbdee2;
                    width: 18px;
                    height: 18px;
                    border: 1px solid #dbdee2;
                    text-align: center;
                    line-height: 18px;
                    text-transform: uppercase;
                    font-weight: 600;
                    margin-top: 2px;
                    margin-bottom: 2px;
                    &.active{
                        color: #3562cd;
                        border: 1px solid #3562cd;
                    }
                    &:nth-child(odd){
                        margin-right: 4px;
                    }
                }
            }
            .lang{
                p{
                    display: inline-block;
                    padding-left: 23px;
                    background-position: left top;
                    background-repeat: no-repeat;
                    background-image: url('./../img/pl_flag.png');
                    text-transform: uppercase;
                }
            }
            .event{
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;
                min-height: 100%;
                border-left: 1px solid #e3e6e9;
                border-right: 1px solid #e3e6e9;
                div{
                    height: 50%;
                    width: 100%;
                    line-height: 35px;
                    padding: 0 13px;
                    &:first-child{
                        border-bottom: 1px solid #e3e6e9;
                    }
                }
            }
        }
    }
}

.more{
    padding: 60px 0;
    text-align: center;
    @media only screen and (max-width: 1500px) {
        padding: 40px 0;
    }
}

.photo_galery{
    background-image: url('./../img/snow2.png');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    height: 580px;
    @media only screen and (max-width: 991px) {
        height: auto;
    }
    .container{
        position: relative;
        bottom: -80px;
        @media only screen and (max-width: 1500px) {
            bottom: -50px;
        }
    }
    .section_header{
        padding:0 0 20px 0;
        @media only screen and (max-width: 1500px) {
            padding: 0;
        }
        div.default_font{
            h2{
                color: $white;
            }
        }
    }
    .galry_carousel{
        position: relative;
        .carousel_content{
            overflow: hidden;
            ul{
                position: relative;
                white-space: nowrap;
                li{
                    width: 100%;
                    margin-right: 5%;
                    display: inline-block;
                    vertical-align: top;
                    div.grid_galery{
                        position: relative;
                        white-space: nowrap;
                        display: grid;
                        grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
                        grid-gap: 30px;
                        grid-auto-rows: minmax(225px, auto);
                        grid-auto-flow: dense;
                        @media only screen and (max-width: 991px) {
                            grid-auto-rows: minmax(27vw, auto);
                            grid-gap: 15px;
                        }
                        div.grid_image{
                            white-space: normal;
                            position: relative;

                            overflow: hidden;

                            .image{
                                @include fill;
                                @include bg_pos;
                                background-size: cover;
                                padding: 20px;
                                @include anim;
                                &:hover{
                                    transform: scale(1.05);
                                }
                                .category{
                                    position: absolute;
                                    bottom: 20px;
                                    p{
                                        text-transform: none !important;
                                        background: rgba(146, 149, 152, 0.8);
                                        margin-bottom: 0;
                                    }
                                }
                            }
                            &.large{
                                grid-column-end: span 2;
                                grid-row-end: span 2;
                                @media only screen and (max-width: 991px) {
                                    grid-column-end: span 4;
                                }
                            }
                            &.small{
                                grid-column-end: span 1;
                                grid-row-end: span 1;
                                @media only screen and (max-width: 991px) {
                                    grid-column-end: span 2;
                                }
                            }
                        }
                    }
                }
            }
        }
        .carousel_nav{
            width: 63px;
            position: absolute;
            height: 100%;
            top: 0;
            right: -31.5px;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;

            -ms-flex-align: center;
            -webkit-align-items: center;
            -webkit-box-align: center;
            @media only screen and (max-width: 991px) {
                right: 0;
                width: auto;
            }
            > div{
                width: 100%;
                @media only screen and (max-width: 991px) {
                    width: auto;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
            .rwd_arr{
                display: none;
                @media only screen and (max-width: 991px) {
                    display: block;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background-color:$white;
                    background-repeat:no-repeat;
                    background-position: center;

                }
                &.rwd_arr_left{
                    background-image:url('./../img/sl_arr_left.png');
                    margin-right: auto;
                    margin-bottom: 30px;
                }
                &.rwd_arr_right{
                    background-image:url('./../img/sl_arr_right.png');
                    margin-left: auto;
                }
            }
            .arr{
                cursor: pointer;
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                @include bg_pos;
                border-radius: 50%;
                background-color: $white;
                -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.75);
                -moz-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.75);
                @media only screen and (max-width: 991px) {
                    background-color: transparent;
                    box-shadow: none;
                    background-image: none !important;
                }
                &.arr_left{
                    background-image: url('./../img/sl_arr_left.png');
                    margin-bottom: 15px;
                }
                &.arr_right{
                    background-image: url('./../img/sl_arr_right.png');
                }
            }
        }
    }
}

.social_media{
    padding-top: 130px;
    padding-bottom: 95px;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-image: url('./../img/social_bg.jpg');
    background-size: cover;
    @media only screen and (max-width: 1500px) {
        padding-top: 100px;
    }
    .section_header{
        .cover{
            //background: #fefefe;
        }
    }
    div.social_boxes{
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        height: 590px;
        margin: 0 -15px;
        @media only screen and (max-width: 991px) {
            display: block;
            height: auto;
        }
        > div{
            margin: 0 15px;
            width: 50%;
            @media only screen and (max-width: 991px) {
                width: 100%;
                margin: 0;
                border-bottom: 2px solid #ccd0d1;
            }
        }
        .facebook_box{
            .box_header{
                background-image: url('./../img/main_social_icon1.png');
            }
        }
        .twitter_box{
            .box_header{
                background-image: url('./../img/main_social_icon2.png');
            }
        }
        .instagram_box{
            .box_header{
                background-image: url('./../img/main_social_icon3.png');
            }
        }
        .box_header{
            height: 57px;
            line-height: 57px;
            width: 100%;
            background-color: $white;
            color: #383838;
            font-size: 12.5px;
            padding-left: 10px;
            padding-right: 50px;
            background-position: right 20px center;
            background-repeat: no-repeat;
            p{
                background-image: url('./../img/main_social_icon_pzn.png');
                padding-left: 53px;
                background-repeat: no-repeat;
                background-position: left center;
            }
        }
        .image{
            height: calc(100% - (57px + 63px));
            @include bg_pos;
            background-size: cover;
            @media only screen and (max-width: 991px) {
                height: 250px;
            }
        }
        .desc{
            height: 63px;
            background: $white;
            padding: 0 10px;
            @media only screen and (max-width: 991px) {
                height: auto;
            }
            > div{
                &.title{
                    height: 60%;
                    font-size: 12.5px;
                    color: #383838;
                    padding-top: 13px;
                    p{
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                &.links{
                    font-size: 11px;
                    color: #bababa;
                    ul{
                        li{
                            display: inline-block;
                            vertical-align: top;
                            &:last-child{
                                a{
                                    &:after{
                                        display: none;
                                    }
                                }
                            }
                            a{
                                color: inherit;
                                &:after{
                                    margin: 0 13px;
                                    content: '•';
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .social_carousels{
        .categories{
            margin: 70px 0 40px 0;
            @media only screen and (max-width: 991px) {
                margin: 30px 0;
            }
            > ul{
                > li{
                    width: calc(100% / 6);
                }
            }
        }
        .carousel_holder{
            position: relative;
            .arr_holder_left{
                height: 100%;
                width: 63px;
                position: absolute;
                top: 0;
                right: calc(100% + 30px);
                @include vertical;
            }
            .arr_holder_right{
                height: 100%;
                width: 63px;
                position: absolute;
                top: 0;
                left: calc(100% + 30px);
                @include vertical;
            }
            .arr{
                cursor: pointer;
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                @include bg_pos;
                border-radius: 50%;
                background-color: $white;
                -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.75);
                -moz-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.75);
                &.arr_left{
                    background-image: url('./../img/sl_arr_left.png');
                    margin-bottom: 15px;
                    @media only screen and (max-width: 1660px) {
                       position: relative;
                        left: 100%;
                    }
                    @media only screen and (max-width: 991px) {
                        left: calc(100% + 30px)
                    }
                }
                &.arr_right{
                    background-image: url('./../img/sl_arr_right.png');
                    @media only screen and (max-width: 1660px) {
                        position: relative;
                        right: 100%;
                    }
                    @media only screen and (max-width: 991px) {
                        right: calc(100% + 30px)
                    }
                }
            }
        }
        .social_carousel_content{
            overflow: hidden;
            //margin: 0 -30px;
            > div {
                > ul {
                    position: relative;
                    white-space: nowrap;
                    //margin: 0 15px;

                    > li {
                        //padding: 0 15px;
                        white-space: normal;
                        display: inline-block;
                        vertical-align: top;
                        width: 23%;
                        margin-right: 2.66%;
                        @media only screen and (max-width: 1200px) {
                            width: 48.6%;
                        }
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            margin-right: 5%;
                        }

                        .facebook_box {
                            .box_header {
                                background-image: url('./../img/main_social_icon1.png');
                            }
                        }

                        .twitter_box {
                            .box_header {
                                background-image: url('./../img/main_social_icon2.png');
                            }
                        }

                        .instagram_box {
                            .box_header {
                                /*background-image: url('./../img/main_social_icon3.png');*/
                            }
                        }

                        .box_header {
                            height: 57px;
                            line-height: 57px;
                            width: 100%;
                            background-color: $white;
                            color: #383838;
                            font-size: 12.5px;
                            padding-left: 10px;
                            padding-right: 50px;
                            background-position: right 20px center;
                            background-repeat: no-repeat;

                            p {
                                background-image: url('./../img/main_social_icon3.png');
                                padding-left: 53px;
                                background-repeat: no-repeat;
                                background-position: left center;
                            }
                        }

                        .image {
                            //height: 220px;
                            height: 0;
                            padding-bottom: 100%;
                            @include bg_pos;
                            background-size: cover;
                        }

                        .desc {
                            height: 40px;
                            line-height: 40px;
                            background: $white;
                            padding: 0 10px;

                            > div {
                                &.title {
                                    height: 60%;
                                    font-size: 12.5px;
                                    color: #383838;
                                    padding-top: 13px;

                                    p {
                                        display: -webkit-box;
                                        -webkit-line-clamp: 1;
                                        -webkit-box-orient: vertical;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                }

                                &.links {
                                    font-size: 11px;
                                    color: #bababa;

                                    ul {
                                        li {
                                            display: inline-block;
                                            vertical-align: top;

                                            &:last-child {
                                                a {
                                                    &:after {
                                                        display: none;
                                                    }
                                                }
                                            }

                                            a {
                                                color: inherit;

                                                &:after {
                                                    margin: 0 13px;
                                                    content: '•';
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.sections{
    background-position: center top;
    background-repeat: no-repeat;
    background-image: url('./../img/snow1.png');
    background-size: 100% auto;
    &.for_subpages{
        background-image: url('./../img/snow4.png');
        padding-top: 80px;
        background-color: #fcfcfc;
        @media only screen and (max-width: 1500px) {
            padding-top: 50px;
        }
        @media only screen and (max-width: 991px) {
            padding-top: 30px;
        }
    }
}

.page_footer{
    div{
        &.main_partents{
            padding: 30px 0;
            div.logo{
                height: 130px;
                width: 100%;
                @include bg_pos;
                margin-bottom: 10px;
                @media only screen and (max-width: 1500px) {
                    transform: scale(0.7);
                }
                @media only screen and (max-width: 1200px) {
                    background-size: auto 50%;
                    transform: scale(0.8);
                }
            }
            div.desc{
                font-family: $special_font;
                font-size: 12.5px;
                line-height: 19px;
                color: #7e8594;
                text-align: center;
                text-transform: uppercase;
                p{
                    font-weight: 500;
                    margin-bottom: 15px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                strong{
                    font-size: 16px;
                    line-height: 26px;
                }
            }
        }
        &.minor_partners{
            padding: 25px 0;
            background-position: left top;
            background-repeat: repeat-x;
            background-image: url('./../img/minor_bg.jpg');
            background-size: contain;
            .minor_partners_list{
                ul{
                    text-align: center;
                    > li{
                        display: inline-block;
                        vertical-align: middle;
                        //margin: 0 1.5%;
                        img{
                            height: 94px;
                            //transform: scale(0.9);
                            @media only screen and (max-width: 1500px) {
                                height: 70px;
                            }
                            @media only screen and (max-width: 1200px) {
                                height: 60px;
                            }
                        }
                        @media only screen and (max-width: 991px) {
                            margin: 0;
                            width: 33.33%;
                            line-height: 60px;
                            img{
                                transform: scale(0.7);
                            }
                        }
                    }
                }
            }
        }
        &.newsletter{
            background-color: #f9f9f9;
            background-repeat: no-repeat;
            background-position: center top;
            background-image: url('./../img/newsletter_bg.png');
            position: relative;
            z-index: 3;
            background-size: cover;
            .section_header{
                margin-bottom: 0;
            }
            .form_holder{
                position: relative;
                z-index: 3;
            }
            .desc_holder{
                height: 195px;
                @include vertical;
                @media only screen and (max-width: 1500px) {
                    height: 170px;
                }
                h3{
                    margin-bottom: 15px;
                }
            }
            ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: $white;
            }
            ::-moz-placeholder { /* Firefox 19+ */
                color: $white;
            }
            :-ms-input-placeholder { /* IE 10+ */
                color: $white;
            }
            :-moz-placeholder { /* Firefox 18- */
                color: $white;
            }
            .newsletter_holder{
                display: flex;
                @media only screen and (max-width: 991px) {
                    display: block;
                    width: 100%;
                }
                > div{

                    position: relative;
                    &.n_holder1{
                        width: calc(100% - (272px * 2));
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                    }
                    &.n_holder2{
                        width: 272px;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            padding: 10px 0;
                        }
                    }
                    &.n_holder3{
                        width: 272px;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                    }
                }
            }

            .chosen-container-single {
                .chosen-single {
                    div{
                        width: 50px;
                        @media only screen and (max-width: 991px) {
                            width: 95px;
                        }
                        b{
                            background-repeat: no-repeat;
                            background-position: center;
                            background-image: url('./../img/select_arr.png');
                        }
                    }
                }
            }
            .chosen-container-single {
                .chosen-single{
                    height: 72px;
                    line-height: 72px;
                    font-size: 13.5px;
                    font-weight: 500;
                    border-radius: 0;
                    padding: 0 20px;
                    background: #929598;
                    color:$white;
                    box-shadow: none;
                    border: none;
                    border-left: 1px solid rgba(255,255,255,0.2);
                    font-family: "Red Hat Display", sans-serif;
                    @media only screen and (max-width: 991px) {
                        border-left: none;
                        border-top: 1px solid rgba(255,255,255,0.2);
                        padding: 0 40px;
                    }
                    span{
                        font-weight: 500;
                    }
                }
            }
            .chosen-container {
                .chosen-drop{
                    background: #929598;
                    color:$white;
                    font-size: 13.5px;
                    box-shadow: none;
                    border: none;
                    border-radius:0;
                }
                .chosen-results{
                    color:$white;
                    li{
                        line-height: 25px;
                        font-size: 13.5px;
                        font-weight: 500;
                        font-family: "Red Hat Display", sans-serif;
                        padding: 5px 20px;
                        @media only screen and (max-width: 991px) {
                            padding-left: 40px;
                        }
                        &.highlighted{
                            background: #cd1b23;
                        }
                    }
                }
            }
            input{
                font-family: $special_font;
                color: $white;
                border: none;
                outline: none;
                vertical-align: top;
                height: 72px;
                width: 100%;
                &.n_input{
                    padding: 0 40px;
                    //height: 72px;
                    //width: calc(100% - 272px);
                    font-size: 13.5px;
                    font-weight: 500;
                    background: #929598;
                   /* @media only screen and (max-width: 1500px) {
                        height: 55px;
                    }
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }*/
                }
                &.n_submit{
                    //height: 72px;
                    //width: 272px;
                    background-color: #043168;
                    font-size: 12.5px;
                    font-weight: 700;
                    padding-left: 40px;
                    background-position: right 42px center;
                    background-repeat: no-repeat;
                    background-image: url('./../img/more_arr2.png');
                    text-align: left;
                    /*@media only screen and (max-width: 1500px) {
                        height: 55px;
                    }
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }*/
                }
            }
        }
        &.general_footer{
            position: relative;
            z-index: 2;
            margin-top: -36px;
            color: #6a6b6e;
            background: rgba(24,25,29,1);
            background: -moz-linear-gradient(-45deg, rgba(24,25,29,1) 0%, rgba(29,38,47,1) 100%);
            background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(24,25,29,1)), color-stop(100%, rgba(29,38,47,1)));
            background: -webkit-linear-gradient(-45deg, rgba(24,25,29,1) 0%, rgba(29,38,47,1) 100%);
            background: -o-linear-gradient(-45deg, rgba(24,25,29,1) 0%, rgba(29,38,47,1) 100%);
            background: -ms-linear-gradient(-45deg, rgba(24,25,29,1) 0%, rgba(29,38,47,1) 100%);
            background: linear-gradient(135deg, rgba(24,25,29,1) 0%, rgba(29,38,47,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#18191d', endColorstr='#1d262f', GradientType=1 );
            @media only screen and (max-width: 1500px) {
                margin-top: -27.5px;
            }
            div{
                &.general_footer_top{
                    font-family: $special_font;
                    padding-top: 74px;
                    border-bottom: 1px solid #2d2f34;
                    @media only screen and (max-width: 991px) {
                        padding-top: 60px;
                    }
                    .footer_contact{
                        ul{
                            li{
                                display: inline-block;
                                vertical-align: top;
                                margin-right: 80px;
                                min-width: 15%;
                                @media only screen and (max-width: 1500px) {
                                    margin-right: 60px;
                                }
                                @media only screen and (max-width: 1200px) {
                                    margin-right: 40px;
                                    &:first-child{
                                        width: 255px;
                                    }

                                }
                                @media only screen and (max-width: 991px) {
                                    margin-right: 0;
                                    width: 100%;
                                    &:first-child{
                                        margin-bottom: 20px;
                                    }
                                }
                                .fast_contact{
                                    p,a{
                                        font-weight: 700;
                                    }
                                    a{
                                        @include anim;
                                        &:hover{
                                            color: $accent_color1 !important;
                                            text-decoration: none;
                                        }
                                    }
                                    div{
                                        color: #6a6b6e;
                                        font-size: 11.5px;
                                    }
                                    &.default_font{
                                        color: #fbfbfb;
                                        a{
                                            color: inherit;
                                        }
                                    }
                                }
                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                        }
                    }

                }
                &.to_up{
                    @media only screen and (max-width: 991px) {
                        display: none;
                    }
                    img{
                        cursor: pointer;
                        margin-top: 45px;
                    }
                }
                .footer_menu{
                    padding: 30px 0;
                    margin-bottom: 20px;
                    @media only screen and (max-width: 991px) {
                        margin-bottom: 0;
                    }
                    ul{
                        li{
                            display: inline-block;
                            vertical-align: top;
                            width: calc(100% / 7);
                            @media only screen and (max-width: 991px) {

                                width: 100%;
                            }
                            a{
                                font-size: 11.5px;
                                color: #6a6b6e;
                                font-weight: 700;
                                line-height: 27px;
                                @include anim;
                                &:hover{
                                    color: $white;
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
                &.general_footer_bottom{
                    padding: 50px 0;
                    font-size: 12.5px;
                    color: #6a6b6e;
                    @media only screen and (max-width: 1500px) {
                        padding: 35px 0;
                    }
                    @media only screen and (max-width: 991px) {
                        text-align: center;
                    }
                    p{
                        font-weight: 500;
                    }
                    ul{
                        @media only screen and (max-width: 991px) {
                            padding-top: 30px;
                        }
                        li{
                            display: inline-block;
                            vertical-align: middle;
                            margin-right: 20px;
                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .text-right{
        text-align: center;
    }
    .side_left, .side_right{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

.top{
    background: $white;
    padding-top: 40px;
    height: 180px;
    @media only screen and (max-width: 1500px) {
        height: 160px;
    }
    @media only screen and (max-width: 991px) {
        height: auto;
        min-height: 140px;
    }
    &.for_events_details{
        font-size: 14.5px;
        &.for_events_details_details{
            height: auto;
        }
        .title{
            h1{
                background-repeat: no-repeat;
                background-position: left top 23px;
                background-repeat: no-repeat;
                padding-top: 15px;
                @media only screen and (max-width: 991px) {
                    background-position: left top 12px;
                }
            }
            p{
                color: #787878;
                line-height: 30px;
            }
        }
        .top_details{
            ul{
                margin-top: 27px;
                padding: 27px 0;
                border-top: 1px solid #e7e7e7;
                @media only screen and (max-width: 991px) {
                    padding: 27px 0 0 0;
                }
                li{
                    display: inline-block;
                    vertical-align: top;
                    padding-right: 34px;
                    margin-right: 34px;
                    border-right: 1px solid #e7e7e7;
                    color: #787878;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        border-bottom: 1px solid #e7e7e7;
                        padding-left: 0;
                        margin-right: 0;
                        padding-bottom: 15px;
                        margin-bottom: 15px;
                        border-right: none;
                        &:last-child{
                            border-bottom: none;
                        }
                    }
                    &:last-child{
                        margin-right: 0;
                        padding-right: 0;
                        border-right: none;
                    }
                }
            }
        }
    }
    &.only_nav{
        height: auto;
        padding: 45px 0 30px 0;
        position: relative;
        z-index: 2;
    }
    .nav_way{
        margin-bottom: 15px;
        ul{
            li{
                display: inline-block;
                vertical-align: top;
                &:last-child{
                    a{
                        border-bottom: 1px solid #9da2ac;
                        &:after{
                            display: none;
                        }
                    }
                }
                a{
                    font-size: 10.5px;
                    color: #9da2ac;
                    text-decoration: none;
                    //text-transform: uppercase;
                    font-weight: 600;
                    &:after{
                        margin: 0 8px;
                        content: '-';
                    }
                }
            }
        }
    }
    .subpage_header{
        > div{
            display: inline-block;
            vertical-align: top;
            &.title{
                width: calc(100% - 400px);
                font-family: $special_font;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                h1{
                    font-size: 45px;
                    line-height: 55px;
                    font-weight: 700;
                    @media only screen and (max-width: 1500px) {
                        font-size: 35px;
                        line-height: 45px;
                    }
                    @media only screen and (max-width: 991px) {
                        font-size: 27px;
                        line-height: 37px;
                    }
                }
            }
            &.filters{
                text-align: right;
                width: 400px;
                padding-top: 15px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    padding-bottom: 20px;
                }
                ul{
                    li{
                        display: inline-block;
                        vertical-align: top;
                        @media only screen and (max-width: 991px) {
                            &:first-child{
                                display: none;
                            }
                        }
                        &:last-child{
                            padding-top: 6px;
                        }
                        p{
                            color: $black;
                            font-weight: 500;
                            padding-right: 25px;
                        }
                        &:last-child{
                            width: 200px;
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                            }
                        }
                        .chosen-container-single {
                            text-transform: uppercase;
                            text-align: left;
                            &.chosen-container-active{
                                .chosen-single{

                                }
                            }
                            .chosen-single {
                                border-radius: 0;
                                background: transparent;
                                border: none;
                                box-shadow: none;
                                border-bottom: 1px solid #7b6d6d;
                                padding: 0;
                                font-family: $special_font;
                                color: $accent_color1;
                                font-weight: 700;
                                div{
                                    b{
                                        background-repeat: no-repeat;
                                        background-position: center right;
                                        background-image: url("./../img/select_arr.png");
                                    }
                                }
                            }
                            .chosen-drop{
                                background: $theme_color1;
                                box-shadow: none;
                                .active-result{
                                    font-size: 13px;
                                    font-family: $special_font;
                                    &.highlighted{
                                        background: $accent_color1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.pioromycalendar2_ajax_box_pagination{
    .paggination_box{
        margin-top: 60px;
        height: 46px;
        font-family: $special_font;
        border: none;
        border-bottom: 2px solid #dfdfdf;
        > div.numbers {
            ul {
                li{
                    display: inline-block;
                    vertical-align: top;
                    min-width: 30px;
                    line-height: 30px;
                    text-align: center;
                    height: 30px;
                    border-radius: 50%;
                    margin: 0 5px;
                    a{
                        line-height: 30px;
                        font-size: 13px;
                        @include anim;
                    }
                    &.active{
                        background: $accent_color1;
                        a{
                            font-size: 13px;
                            color: $white;
                        }
                    }
                }
            }
        }
        > div.btn_sites.next {
            text-align: center;
            background: #f2f2f2;
            a{
                display: inline-block;
                padding-right: 32px;
                font-weight: 700;
                color: #7e8086;
                background-image: url('./../img/site_arr2.png');
                background-size: auto;
            }
        }
        > div.btn_sites.prev{
            text-align: center;
            background: #f2f2f2;
            a{
                display: inline-block;
                padding-left: 32px;
                font-weight: 700;
                color: #7e8086;
                background-image: url('./../img/site_arr1.png');
                background-size: auto;
            }
        }
    }
}

.paggination_boxx{
    text-align: center;
    font-family: $special_font;
    > ul{
        display: inline-block;
        background: $white;
        border-bottom: 2px solid #dfdfdf;
        > li{
            a{
                display: block;
                color: #7e8086;
                &:hover{
                    text-decoration: none;
                }
            }
            display: inline-block;
            vertical-align: middle;
            height: 100%;
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
            &.arr_holder{
                text-align: center;
                height: 44px;
                line-height: 44px;
                background: #f2f2f2;
                &.prev{
                    img{
                        margin-right: 10px;
                    }
                }
                &.next{
                    img{
                        margin-left: 10px;
                    }
                }
                a{
                    padding: 0 30px;
                    font-weight: 700;
                }
            }
            &.sites_holder{
                background: $white;
                @media only screen and (max-width: 991px) {
                    padding: 10px 0 5px 0;
                }
                ul{
                    padding: 0 10px;
                    li{
                        display: inline-block;
                        vertical-align: top;
                        min-width: 30px;
                        line-height: 30px;
                        text-align: center;
                        height: 30px;
                        border-radius: 50%;
                        margin: 0 5px;

                        &.active{
                            background: $accent_color1;
                            a{
                                color: $white;
                            }
                        }
                    }
                }
            }
            &.go_to{
                padding: 0 60px 0 30px;
                font-size: 11.5px;
                @media only screen and (max-width: 991px) {
                    padding: 5px 0 10px 0;
                }
                div{
                    display: inline-block;
                    vertical-align: middle;
                    input{
                        outline: none;
                        vertical-align: middle;
                        &.site_input{
                            height: 25px;
                            width: 40px;
                            border: 1px solid #e1e1e1;
                            border-radius: 5px;
                            margin: 0 10px;
                        }
                        &.site_submit{
                            background-color: none;
                            border: none;
                            font-weight: 700;
                            padding-right: 30px;
                            background-repeat: no-repeat;
                            background-position: right center;
                            background-image: url('./../img/site_arr3.png');
                        }
                    }
                }
            }
        }
    }
}

.office{
    .links{
        ul{
            margin: 0 -30px;
            @media only screen and (max-width: 1500px) {
                margin: 0 -20px;
            }
            li{
                padding: 0 30px;
                display: inline-block;
                vertical-align: top;
                width: 50%;
                margin-bottom: 60px;
                @media only screen and (max-width: 1500px) {
                    margin-bottom: 40px;
                    padding: 0 20px;
                }
                @media only screen and (max-width: 991px) {
                    margin-bottom: 30px;
                    width: 100%;
                }
                &.tab_active{
                    .link_box{
                        color: $white;
                        background-color: $accent_color1;
                        background-image: url('./../img/site_arr4.png');
                    }
                }
                &:hover{
                    a{
                        text-decoration: none;

                    }
                    .link_box{
                        color: $white;
                        background-color: $accent_color1;
                        background-image: url('./../img/site_arr4.png');
                    }
                }
                .link_box{
                    cursor: pointer;
                    @include anim;
                    height: 140px;
                    width: 100%;
                    background: $white;
                    @include vertical;
                    padding: 0 120px 0 60px;
                    font-size: 30px;
                    line-height: 40px;
                    font-family: $special_font;
                    color: $black;
                    background-repeat: no-repeat;
                    background-position: right 60px center;
                    background-image: url('./../img/site_arr5.png');

                    @media only screen and (max-width: 1500px) {
                        height: 100px;
                        font-size: 20px;
                        line-height: 30px;
                        padding:0 100px 0 40px;
                    }
                    @media only screen and (max-width: 991px) {
                        padding: 0 50px 0 20px;
                        background-position: right 20px center;
                        font-size: 20px;
                        line-height: 30px;
                    }
                    p{
                        font-weight: 700;
                    }
                }
            }
        }
    }
    .office_list{
        ul{
            li{
                display: none;
            }
        }
    }
    .newsletter_holder{
        display: flex;
        @media only screen and (max-width: 991px) {
            display: block;
            width: 100%;
        }
        > div{

            position: relative;
            &.n_holder1{
                width: calc(100% - 272px);
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
            }
            &.n_holder2{
                width: 1px;
                @media only screen and (max-width: 991px) {
                    display: none;
                }
            }
            &.n_holder3{
                width: 271px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
            }
        }
        .chosen-container-single {
            .chosen-single {
                div{
                    width: 50px;
                    @media only screen and (max-width: 991px) {
                        width: 95px;
                    }
                    b{
                        background-repeat: no-repeat;
                        background-position: center;
                        background-image: url('./../img/select_arr.png');
                    }
                }
            }
        }
        .chosen-container-single {
            .chosen-single{
                height: 72px;
                line-height: 72px;
                font-size: 13.5px;
                font-weight: 500;
                border-radius: 0;
                padding: 0 20px;
                background: #929598;
                color:$white;
                box-shadow: none;
                border: none;
                border-left: 1px solid rgba(255,255,255,0.2);
                font-family: "Red Hat Display", sans-serif;
                @media only screen and (max-width: 991px) {
                    border-left: none;
                    border-top: 1px solid rgba(255,255,255,0.2);
                    padding: 0 40px;
                }
                span{
                    font-weight: 500;
                }
            }
        }
        .chosen-container {
            .chosen-drop{
                background: #929598;
                color:$white;
                font-size: 13.5px;
                box-shadow: none;
                border: none;
                border-radius:0;
            }
            .chosen-results{
                color:$white;
                li{
                    line-height: 25px;
                    font-size: 13.5px;
                    font-weight: 500;
                    font-family: "Red Hat Display", sans-serif;
                    padding: 5px 20px;
                    @media only screen and (max-width: 991px) {
                        padding-left: 40px;
                    }
                    &.highlighted{
                        background: #cd1b23;
                    }
                }
            }
        }
        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $white;
        }
        ::-moz-placeholder { /* Firefox 19+ */
            color: $white;
        }
        :-ms-input-placeholder { /* IE 10+ */
            color: $white;
        }
        :-moz-placeholder { /* Firefox 18- */
            color: $white;
        }
        input {
            font-family: $special_font;
            color: $white;
            border: none;
            outline: none;
            vertical-align: top;
            height: 72px;
            width: 100%;

            &.n_input {
                padding: 0 40px;
                //height: 72px;
                //width: calc(100% - 272px);
                font-size: 13.5px;
                font-weight: 500;
                background: #929598;
                /* @media only screen and (max-width: 1500px) {
                     height: 55px;
                 }
                 @media only screen and (max-width: 991px) {
                     width: 100%;
                 }*/
            }

            &.n_submit {
                //height: 72px;
                //width: 272px;
                background-color: #043168;
                font-size: 12.5px;
                font-weight: 700;
                padding-left: 40px;
                background-position: right 42px center;
                background-repeat: no-repeat;
                background-image: url('./../img/more_arr2.png');
                text-align: left;
                /*@media only screen and (max-width: 1500px) {
                    height: 55px;
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }*/
            }
        }
    }
}
.default_form{
    padding-bottom: 50px;
    @media only screen and (max-width: 991px) {
        padding-bottom: 0px;
    }
    label.default_label{
        font-family: $special_font;
        font-size: 16px;
        display: block;
        line-height: 55px;
        @media only screen and (max-width: 1200px) {
            font-size: 14px;
        }
        span{
            color: $accent_color1;
        }
    }

    .holder{
        margin-bottom: 20px;
        @media only screen and (max-width: 991px) {
            margin-bottom: 0;
        }
        .chosen-container{
            .chosen-drop{
                border: 1px solid #dcdcdc;
                border-top: none;
                margin-top: -4px;
            }
            .chosen-results {
                li{
                    font-family: $special_font;
                    color: #767676;
                    font-weight: 700;
                }
            }
        }
        .chosen-container-single {
            text-align: left;
            .chosen-search {
                input[type="text"]{
                    border: 1px solid #dcdcdc;
                }
            }
            &.chosen-container-active{
                .chosen-single{

                }
            }
            .chosen-single {
                height: 55px;
                border-radius: 5px;
                background: $white;
                box-shadow: none;
                border: 1px solid #dcdcdc;
                padding: 0;
                font-family: $special_font;
                color: #767676;
                font-weight: 700;
                line-height: 55px;

                span{
                    padding: 0 60px 0 30px;
                }
                div{
                    width: 60px;
                    b{
                        background-repeat: no-repeat;
                        background-position: center;
                        background-image: url("./../img/select_arr2.png");
                    }
                }
            }
            .chosen-drop{
                background: $white;
                box-shadow: none;
                .active-result{
                    font-size: 13px;
                    font-family: $special_font;
                    &.highlighted{
                        background: $accent_color1;
                        color: $white;
                    }
                }
            }
        }
        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: #767676;
        }
        ::-moz-placeholder { /* Firefox 19+ */
            color: #767676;
        }
        :-ms-input-placeholder { /* IE 10+ */
            color: #767676;
        }
        :-moz-placeholder { /* Firefox 18- */
            color: #767676;
        }
        input{
            outline: none;
            &.default_input{
                height: 55px;
                width: 100%;
                border: 1px solid #dcdcdc;
                border-radius: 5px;
                color: #767676;
                padding: 0 30px;
                font-size: 13px;
                font-weight: 700;
            }
            &.default_submit{
                width: 233px;
                height: 55px;
                background: $accent_color1;
                color: $white;
                font-family: $special_font;
                font-size: 12.5px;
                font-weight: 700;
                text-align: left;
                padding-left: 45px;
                background-repeat: no-repeat;
                background-position: right 49px center;
                background-image: url('./../img/sub_arr.png');
                border: none;
            }
        }
        .default_textarea{
            min-height: 190px;
            width: 100%;
            border: 1px solid #dcdcdc;
            border-radius: 5px;
            color: #767676;
            padding: 15px 30px;
            font-size: 13px;
            font-weight: 700;
            resize: none;
            outline: none;
        }
        .myform2_dropzone_box{
            min-height: 55px;
            width: 100%;
            border: 1px solid #dcdcdc;
            border-radius: 5px;
            background: $white;
            padding: 0 30px;
            font-size: 13px;
            font-weight: 700;
            line-height: 55px;
            overflow: hidden;
            > ul{
                > li{
                    display: block;
                    .name{
                        > div{
                            padding-bottom: 15px;
                        }
                    }
                }
            }
            .long_name{
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height:20px;
            }
            .dropzone_box{
                padding: 0;
                color: #767676;
                background-image: none;
            }
            .previewsContainer {
                .row{
                    > div{
                        vertical-align:middle;
                    }
                    &:first-child{
                        padding-top: 0;
                        > div.thumb {
                            img{
                                max-width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
            }
        }
    }
    .rules{
        @media only screen and (max-width: 991px) {
            padding-top: 15px;
        }
        ul{
            li{
                display: inline-block;
                vertical-align: top;
                &.check_holder{
                    width: 23px;
                    .checkbox_image_box{
                        width: 100% !important;
                        position: relative;
                        height: 23px;
                        border: 1px solid #dcdcdc;
                        border-radius: 3px;
                        background: $white;
                        > div{
                            border: none;
                            @include fill;
                            @include bg_pos;
                            &.yes{
                                &.on{
                                    background-color: transparent;
                                    background-image: url('./../img/check_icon.png');
                                }
                            }
                        }
                    }
                }
                &.check_desc{
                    width: calc(100% - 23px);
                    padding-left: 25px;
                    font-size: 14px;
                    line-height: 26px;
                    font-family: $special_font;
                    p{
                        font-weight: 500;
                    }
                }
            }
        }
    }
    .captcha_holder{
        padding: 50px 0;
    }
}

.galery{
    padding-bottom: 60px;
    @media only screen and (max-width: 1500px) {
        padding-bottom: 50px;
    }
    @media only screen and (max-width: 991px) {
        padding-bottom: 30px;
    }
    .gal_list{
        ul{
            margin: 0 -15px;
            li{
                display: inline-block;
                vertical-align: top;
                padding: 0 15px;
                width: 33%;
                margin-bottom: 30px;
                @include anim;
                &:hover{
                    transform: scale(1.05);
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                article{
                    div{
                        &.image{
                            width: 100%;
                            height: 0;
                            padding-bottom: 66%;
                            @include bg_pos;
                            background-size: cover;
                        }
                        &.desc{
                            border: 1px solid #e1e1e1;
                            background: $white;
                            height: 80px;
                            padding-top: 20px;
                            .default_font{
                                padding: 0 55px;
                                color: $black;
                                line-height: 20px;
                                @media only screen and (max-width: 1200px) {
                                    padding: 0 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.galery_details{
    padding-bottom: 80px;
    @media only screen and (max-width: 1500px) {
        padding-bottom: 50px;
    }
    @media only screen and (max-width: 991px) {
        padding-bottom: 30px;
    }
    .desc{
        .default_font{
            margin-bottom: 80px;
            @media only screen and (max-width: 1500px) {
                margin-bottom: 50px;
            }
            @media only screen and (max-width: 1200px) {
                margin-bottom: 30px;
            }
            color: $font_color;
            p{
                font-weight: 600;
            }
        }
    }
    .grid_gallery {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
        grid-gap: 30px;
        grid-auto-rows: minmax(312px, auto);
        grid-auto-flow: dense;
    }

    //box

    .grid_item {
        position: relative;
    }

    .image{
        @include bg_pos;
        background-size: cover;
        @include fill;
        .image_desc{
            @include fill;
            background: rgba(48,41,31,0);
            background: -moz-linear-gradient(top, rgba(48,41,31,0) 30%, rgba(85,85,85,1) 100%);
            background: -webkit-gradient(left top, left bottom, color-stop(30%, rgba(48,41,31,0)), color-stop(100%, rgba(85,85,85,1)));
            background: -webkit-linear-gradient(top, rgba(48,41,31,0) 30%, rgba(85,85,85,1) 100%);
            background: -o-linear-gradient(top, rgba(48,41,31,0) 30%, rgba(85,85,85,1) 100%);
            background: -ms-linear-gradient(top, rgba(48,41,31,0) 30%, rgba(85,85,85,1) 100%);
            background: linear-gradient(to bottom, rgba(48,41,31,0) 30%, rgba(85,85,85,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#30291f', endColorstr='#555555', GradientType=0 );
            .default_font{
                font-family: $special_font;
                padding:10px 20px;
                color: $white;
                position: absolute;
                width: 100%;
                bottom: 0;
                left: 0;
            }
        }
    }

    // wielkosci boxów

    .small{
        grid-column-end: span 1;
        grid-row-end: span 1;
    }

    .medium{
        grid-column-end: span 2;
        grid-row-end: span 1;
    }

    .large {
        grid-column-end: span 2;
        grid-row-end: span 2;
    }
}

.contact, .ski_associations, .box_list{
    padding-bottom: 70px;
    @media only screen and (max-width: 1500px) {
        padding-bottom: 50px;
    }
    @media only screen and (max-width: 991px) {
        padding-bottom: 30px;
    }
    .contact_box{
        padding:50px 60px;
        background: $white;
        @media only screen and (max-width: 1500px) {
            padding: 30px 40px;
        }
        @media only screen and (max-width: 991px) {
            padding: 20px 30px;
            margin-bottom: 30px;
        }
        h3{
            font-size: 18px;
            line-height: 28px;
            font-family: $special_font;
            color: $black;
            margin-bottom: 20px;
        }
    }
    .container{
        > .row{
            margin: 0 -72.5px;
            @media only screen and (max-width: 1500px) {
                margin: 0 -50px;
            }
            @media only screen and (max-width: 991px) {
                margin: 0 -30px;
            }
            > .col-md-6{
                padding: 0 72.5px;
                @media only screen and (max-width: 1500px) {
                    padding: 0 50px;
                }
                @media only screen and (max-width: 991px) {
                    padding: 0 30px;
                }
            }
        }
    }
    .contact_list{
        > .default_font{
            padding-top: 50px;
            margin-bottom: 20px;
        }
        ul{
            li{
                padding-left: 37px;
                background-repeat: no-repeat;
                background-position: left center;
                min-height: 25px;
                margin-bottom: 20px;
                .default_font{
                    font-family: $special_font;
                    p{
                        font-weight: 500;
                        a{
                            font-weight: inherit;
                        }
                    }
                }
                a{
                    color: $accent_color1;
                    border-bottom: 1px solid $accent_color1;
                }
                &:last-child{
                    margin-bottom: 0;
                }
                &.adres{
                    background-image: url('./../img/c_icon1.png');
                }
                &.phone{
                    background-image: url('./../img/c_icon2.png');
                }
                &.mail{
                    background-image: url('./../img/c_icon3.png');
                }
                &.web{
                    background-image: url('./../img/c_icon4.png');
                }
                &.open{
                    background-image: url('./../img/c_icon5.png');
                }
                &.fax{
                    background-image: url('./../img/c_icon6.png');
                }
            }
        }
        .default_font{
            line-height: 18px;
        }
    }
    .info{
        padding-top: 50px;
        font-family: $special_font;
        color: #7e8086;
        p{
            font-weight: 500;
        }
    }
    .default_form{
        div.holder{
            .default_label{
                margin-bottom: 10px;
                height: auto;
                line-height: 20px;
            }
            input{
                &.default_input{
                    border-radius: 0;
                    border: none;
                    border-bottom: 2px solid #eaeaea;
                }
            }
            .default_textarea{
                border-radius: 0;
                border: none;
                border-bottom: 2px solid #eaeaea;
            }
            .text-right{
                margin-top: 20px;
            }
        }
    }
}

.ski_associations{
    padding-bottom: 20px;
    .associations_list{
        padding: 0 60px;
        @media only screen and (max-width: 1200px) {
            padding: 0 40px;
        }
        @media only screen and (max-width: 991px) {
            padding: 0 20px;
        }
        > ul{
            margin: 0 -30px;
            text-align: center;
            > li{
                display: inline-block;
                vertical-align: top;
                width: 50%;
                padding: 0 30px;
                margin-bottom: 60px;
                text-align: left;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-bottom: 0px;
                }
                .contact_box{
                    min-height: 320px;
                    -webkit-box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
                    -moz-box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
                    box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
                }
            }
        }
    }
}

.box_list{
    padding-bottom: 20px;
    .boxx_list{
        text-align: center;
        padding: 0 60px;
        @media only screen and (max-width: 1200px) {
            padding: 0 40px;
        }
        @media only screen and (max-width: 991px) {
            padding: 0 20px;
        }
        > ul{
            margin: 0 -30px;
            > li{
                display: inline-block;
                vertical-align: top;
                width: 50%;
                padding: 0 30px;
                margin-bottom: 60px;
                text-align: left;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-bottom: 0px;
                }
                .contact_box{
                    min-height: 260px;
                    -webkit-box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
                    -moz-box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
                    box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
                }
            }
        }
    }
}

.download{
    text-align: center;
    @media only screen and (max-width: 991px) {
        padding-bottom: 20px;
    }
    .container{
        .row{
            margin-bottom: 50px;
            @media only screen and (max-width: 1500px) {
                margin-bottom: 20px;
            }
            @media only screen and (max-width: 991px) {
                margin-bottom: 0px;
            }
        }
    }
    .download_list{
        ul{
            margin: 0 -15px;
            li{
                display: inline-block;
                vertical-align: top;
                padding: 0 15px;
                width: 50%;
                margin-bottom: 30px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                a{
                    text-decoration: none !important;
                }
                .download_box{
                    background-color: $white;
                    border: 1px solid #eaeaea;
                    border-bottom: 3px solid #eaeaea;
                    padding: 0 70px 0 20px;
                    background-repeat: no-repeat;
                    background-position: right 30px center;
                    background-image: url('./../img/d_icon2.png');
                    @include anim;
                    @media only screen and (max-width: 991px) {
                        padding: 0 20px;
                        background-image: none;
                    }
                    &:hover{
                        background-color: $accent_color1;
                        background-image: url('./../img/d_icon2w.png');
                        div{
                            background-image: url('./../img/d_icon1w.png');
                            color: $white;
                        }
                    }
                    div{
                        height: 60px;
                        width: 100%;
                        line-height: 60px;
                        color: $black;
                        font-family: $special_font;
                        padding-left: 60px;
                        background-repeat: no-repeat;
                        background-position: left center;
                        background-image: url('./../img/d_icon1.png');
                        @include anim;
                        p{
                            font-weight: 500;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }
}

.spit_view{
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    @media only screen and (max-width: 991px) {
        display: block;
    }
    > div{
        &.sections{
            width: calc(100% - 466px);
            @media only screen and (max-width: 1500px) {
                width: calc(100% - 304px);
            }
            @media only screen and (max-width: 1200px) {
                width: calc(100% - 300px);
            }
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
        }
        &.sponsor_side{
            font-family: $special_font;
            width: 466px;
            background-color: $white;
            background-position: left top;
            background-repeat: repeat-x;
            background-image: url('./../img/aside_shadow.jpg');
            -webkit-box-shadow: 0px 0px 65px 0px rgba(48, 41, 31, 0.2);
            -moz-box-shadow: 0px 0px 65px 0px rgba(48, 41, 31, 0.2);
            box-shadow: 0px 0px 65px 0px rgba(48, 41, 31, 0.2);
            @media only screen and (max-width: 1500px) {
                width: 304px;
            }
            @media only screen and (max-width: 1200px) {
                width: 300px;
            }
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
            div.images{
                padding: 50px 15px 0 15px;
                .image{
                    img{
                        width: 100%;
                        height: auto;
                    }
                }
                /* div.image{
                     width: 100%;
                     height: 0;
                     padding-bottom: 100%;
                     @include bg_pos;
                     background-size: 100% auto;
                 }*/
            }
        }
    }
}

.content_width{
    padding: 0 5%;
}

.univeral{
    .desc_holder{
        padding: 50px;
        background: rgba(255,255,255,0.8);
    }
    .main_image{
        .image{
            @include bg_pos;
            width: 100%;
            height: 0;
            padding-bottom: 50%;
            background-size: contain;
            //margin-bottom: 50px;

        }
        .author_image{
            color:#000000;
            padding: 5px;
            padding-bottom: 50px;
            font-size: 11px;
            @media only screen and (max-width: 991px) {
                padding: 10px;
            }
        }
    }
    .main_desc{
        .desc{
            padding-bottom: 50px;
            margin-bottom: 50px;
            border-bottom: 1px solid #d5d5d5;
            @media only screen and (max-width: 991px) {
                padding-bottom: 30px;
                margin-bottom: 30px;
            }
        }
    }
    .download_list{
        padding-bottom: 70px;
        @media only screen and (max-width: 1500px) {
            padding-bottom: 50px;
        }
        @media only screen and (max-width: 991px) {
            padding-bottom: 30px;
        }
        ul{
            margin: 0 -15px;
            li{
                display: inline-block;
                vertical-align: top;
                padding: 0 15px;
                margin-bottom: 10px;
                width: 100%;
                a{
                    text-decoration: none !important;
                }
                .download_box{
                    display: inline-block;
                    background-color: $white;
                    border: 1px solid #eaeaea;
                    border-bottom: 3px solid #eaeaea;
                    padding: 0 135px 0 20px;
                    background-repeat: no-repeat;
                    background-position: right 30px center;
                    background-image: url('./../img/d_icon2.png');
                    @include anim;
                    &:hover{
                        background-color: $accent_color1;
                        background-image: url('./../img/d_icon2w.png');
                        div{
                            background-image: url('./../img/d_icon1w.png');
                            color: $white;
                        }
                    }
                    div{
                        height: 60px;
                        width: 100%;
                        line-height: 60px;
                        color: $black;
                        font-family: $special_font;
                        padding-left: 60px;
                        background-repeat: no-repeat;
                        background-position: left center;
                        background-image: url('./../img/d_icon1.png');
                        @include anim;
                        p{
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
    .other_news{
        background: $theme_color1;
        padding: 50px 0;
        .other_news_list{
            ul{
                margin: 0 -15px;
                li{
                    padding: 0 15px;
                    display: inline-block;
                    vertical-align: top;
                    width: 33.33%;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        margin-bottom: 20px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                    article{
                        height: 500px;
                        width: 100%;
                        position: relative;
                        border-radius: 10px;
                        overflow: hidden;
                        .image{
                            position: absolute;
                            left: 0;
                            top: 0;
                            height: 50%;
                            width: 100%;
                            @include bg_pos;
                            background-size: cover;
                        }
                        .desc_holder{
                            position: absolute;
                            left: 0;
                            top: 50%;
                            height: 50%;
                            width: 100%;
                            background: $white;
                            .desc_holder2{
                                @include fill;
                                display: -ms-flexbox;
                                display: -webkit-flex;
                                display: flex;

                                z-index: 2;

                                -ms-flex-align: center;
                                -webkit-align-items: center;
                                -webkit-box-align: center;
                                .desc{
                                    padding: 40px;
                                    width: 100%;
                                    color: $black;
                                    div{
                                        &.news_category{
                                            margin-bottom: 15px;
                                            p{
                                                font-family: $special_font;
                                                font-weight: 900;
                                                text-transform: uppercase;
                                                display: inline-block;
                                                font-size: 10px;
                                                padding-left: 0;
                                                padding-right: 0;
                                                background: transparent;
                                                border-bottom: 1px solid $black;
                                            }
                                        }
                                        &.news_title{
                                            //min-height: 72px;
                                            height: 108px;
                                            h3{
                                                font-weight: 700;
                                                font-family: $special_font;
                                                font-size: 24px;
                                                line-height: 36px;
                                                display: -webkit-box;
                                                -webkit-line-clamp: 3;
                                                -webkit-box-orient: vertical;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                            }
                                        }
                                        &.news_bottom{
                                            padding-top: 21px;
                                            margin-top: 21px;
                                            border-top: 1px solid $black;
                                            .date{
                                                font-size: 11px;
                                                font-weight: 700;
                                                font-family: $special_font;
                                            }
                                            .more_btn{
                                                font-size: 11px;
                                                background-image: url('./../img/more_arr2.png');
                                                padding-right: 35px;
                                                color: $black;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.sponsors{
    div{
        &.large_sponsors{
            .row{
                margin: 0 -10px;
                > div{
                    padding: 0 10px;
                }
            }
            .sponsor_box{
                margin-bottom: 20px;
                height: 310px;
                width: 100%;
                background-color: $white;
                @media only screen and (max-width: 1500px) {
                    height: 250px;
                }
                @include bg_pos();
                border-bottom: 2px solid #d7d7d6;
                -webkit-box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
                -moz-box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
                box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
                @include vertical;
                @include anim;
                &:hover{
                    transform: scale(1.05);
                }
                > div{
                    width: 100%;
                    div.logo{
                        height: 130px;
                        width: 100%;
                        @include bg_pos;
                        //margin-bottom: 10px;
                        @media only screen and (max-width: 991px) {
                            background-size: contain;
                            transform: scale(0.7);
                        }
                    }
                    div.desc{
                        font-family: $special_font;
                        font-size: 14.5px;
                        line-height: 24px;
                        color: #7e8594;
                        text-align: center;
                        text-transform: uppercase;
                        p{
                            font-weight: 500;
                            margin-bottom: 15px;
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                        strong{
                            font-size: 16px;
                            line-height: 26px;
                        }
                    }
                }
            }
        }
        &.more_sponsors_list{
            padding-top: 45px;
            padding-bottom: 70px;
            @media only screen and (max-width: 1500px) {
                padding-bottom: 50px;
            }
            @media only screen and (max-width: 991px) {
                padding-top: 0;
                padding-bottom: 30px;
            }
            ul{
                margin: 0 -15px;
                text-align: center;
                @media only screen and (max-width: 991px) {
                    margin: 0 -5px;
                }
                li{
                    display: inline-block;
                    vertical-align: top;
                    width: 25%;
                    padding: 0 15px;
                    margin-bottom: 30px;
                    @include anim;
                    &:hover{
                        transform: scale(1.05);
                    }
                    @media only screen and (max-width: 991px) {
                        padding: 0 5px;
                        margin-bottom: 10px;
                    }
                    .minor_sponsor_box{
                        background-color: rgba(255,255,255,1);
                        background-size: cover;
                        @include bg_pos;
                        width: 100%;
                        height: 0;
                        padding-bottom: 66%;
                        border: 1px solid #e0e0e0;
                    }
                }
            }
        }
    }
}

.dyscypline_slider{
    position: relative;
    height: 600px;
    width: 100%;
    .slider_link{
        @include fill;
        z-index: 3;
    }
    @media only screen and (max-width: 1500px) {
        height: 425px;
    }
    @media only screen and (max-width: 991px) {
        height: 350px;
    }
    > ul{
        @include fill;
        > li{
            display: none;
            @include fill;
            &:first-child{
                display: block;
            }
            .d_slider_holder{
                @include fill;
                display: flex;
                flex-flow: row;
                flex-wrap: nowrap;
                @media only screen and (max-width: 991px) {
                    display: block;
                }
                >  div{
                    width: 50%;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                    &.image{
                        @include bg_pos;
                        background-size: cover;
                        @media only screen and (max-width: 991px) {
                            @include fill;
                        }
                    }
                    &.desc{
                        background: $white;
                        padding-top: 140px;
                        padding-left: 70px;
                        @media only screen and (max-width: 991px) {
                            background: rgba(255,255,255,0);
                            @include fill;
                            z-index: 2;
                            text-align: center;
                        }
                        .section_header{
                            @media only screen and (max-width: 991px) {
                                margin-bottom: 20px;
                                padding: 0 15px;
                            }
                            h1{
                                display: -webkit-box;
                                -webkit-line-clamp: 3;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;


                                @media only screen and (max-width: 991px) {
                                    text-align:left;
                                    strong{
                                        padding: 0 5px;
                                        display: inline;
                                        color: #ffffff;
                                        background: rgba(0, 0, 0, 0.5);
                                        box-shadow: 10px 0 0 rgba(0, 0, 0, 0.5), -10px 0 0 rgba(0, 0, 0, 0.5);
                                    }
                                }

                            }
                        }
                        .default_font{
                            color: #787272;
                            p, strong{
                                display: -webkit-box;
                                -webkit-line-clamp: 3;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                        .text-right{
                            padding-top: 40px;
                            @media only screen and (max-width: 991px) {
                                padding-top: 0;
                                display: none;
                            }
                            a{
                                color: #424242;
                            }
                        }
                        .desc_holder{
                            //padding-right: 16%;
                            @media only screen and (max-width: 991px) {
                                padding-right: 0;
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                width: 100%;
                                > div{
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

        }
    }
    .slider_nav2{
        width: 50%;
        position: absolute;
        right: 0;
        top: 0;
        height: 140px;
        padding-left: 70px;
        @include vertical;
        @media only screen and (max-width: 991px) {
            padding-left: 0;
            width: 100%;
            text-align: center;
            height: 60px;
            @include fill;
            padding: 0 15px;
            span{
                width: 100%;
            }
        }
        .desctop_arr{
            @media only screen and (max-width: 991px) {
                display: none;
            }
        }
        .rwd_arr{
            display: none;
            @media only screen and (max-width: 991px) {
                display: block;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background-color:$white;
                background-repeat:no-repeat;
                background-position: center;

            }
            &.rwd_arr_left{
                background-image:url('./../img/sl_arr_left.png');
                margin-right: auto;
            }
            &.rwd_arr_right{
                background-image:url('./../img/sl_arr_right.png');
                margin-left: auto;
            }
        }
        .arr{
            display: inline-block;
            vertical-align: top;
            margin-left: auto;
            width: 60px;
            height:  60px;
            line-height: 60px;
            text-align: center;
            border-radius: 50%;
            background: $theme_color1;
            cursor: pointer;
            position: relative;
            margin-right: 20px;
            z-index: 5;
            @media only screen and (max-width: 991px) {
                background-color:transparent;
                width: 50%;
                margin-right: 0;
                img{
                    display: none;
                }
                @include bg_pos;
                &.arr_left{
                    background-image: url("./../img/sl_arr_left.png");
                    @media only screen and (max-width: 991px) {
                        background-image: none;
                    }
                }
                &.arr_right{
                    background-image: url("./../img/sl_arr_right.png");
                    @media only screen and (max-width: 991px) {
                        background-image: none;
                    }
                }
            }
        }
    }
}

section.exposure{
    height: 325px;
    @include bg_pos;
    background-size: contain;
    @media only screen and (max-width: 1500px) {
        height: 230px;
    }
}

.dyscipline_news{
    padding-top: 80px;
    @media only screen and (max-width: 1500px) {
        padding-top: 40px;
    }
    .section_header{
        .cover{
            //background: $theme_color1;
            //background: #e4e4e5;
            position: relative;
            z-index: 2;
        }
    }
}

.dyscipline_events{
    padding-top: 50px;
    background: #e9e9e9;
    .section_header{
        .cover{
            //background: $theme_color1;
            background: #e9e9e9;
            position: relative;
            z-index: 2;
        }
    }
}

.cadre{
    padding-top: 50px;
    padding-bottom: 50px;
    background: #f7f7f7;
    .section_header{
        .cover{
            //background: $theme_color1;
            background: #f7f7f7;
            position: relative;
            z-index: 2;
        }
    }
    .cadre_toggle_box{
        .cadre_toggles{
            background: $accent_color2;
            ul{
                height: 55px;
                display: flex;
                flex-wrap: nowrap;
                flex-flow: row;
                @media only screen and (max-width: 991px) {
                    display: block;
                    height: auto;
                }
                li{
                    width: calc(100% / 4);
                    @include vertical;
                    padding-left: 20px;
                    background-repeat: no-repeat;
                    background-position: right 20px center;
                    border-right: 1px solid #2b313e;
                    @include anim;
                    font-size: 13.5px;
                    //text-transform: uppercase;
                    color: $white;
                    font-family: $special_font;
                    font-weight: 700;
                    cursor: pointer;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        height: 55px;
                    }
                    p{
                        font-weight: inherit;
                        display: inline-block;
                        position: relative;
                        &:after{
                            content: '';
                            border-bottom: 2px solid $accent_color1;
                            width: 0;
                            left: 50%;
                            position: absolute;
                            top: calc(100% + 5px);
                            @include anim;
                        }
                        span{
                            display: inline-block;
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                            text-align: center;
                            border-radius: 50%;
                            font-size: 13.5px;
                            text-transform: uppercase;
                            font-weight: 600;
                            color: $white;
                            background: #444d60;
                            @include anim;
                            margin-left: 5px;
                        }
                    }
                    &:hover{
                        background-color: $white;
                        color: $accent_color1;
                        border-right: 1px solid $white;
                        p{
                            &:after{
                                width: 100%;
                                left: 0;
                            }
                            span{
                                background: $accent_color1;
                            }
                        }
                    }
                    &:last-child{
                        border-right: none;
                    }
                    &.toggle_active{
                        background-color: $white;
                        color: $accent_color1;
                        border-right: 1px solid $white;
                        p{
                            &:after{
                                width: 100%;
                                left: 0;
                            }
                            span{
                                background: $accent_color1;
                            }
                        }
                    }
                }
            }
        }
        .cadre_page{
            > ul{
                > li{
                    display: none;
                }
            }
        }
        .cadre_toggle_content{
            padding-top: 70px;
            margin: 0 -35px;
            @media only screen and (max-width: 991px) {
                padding-top: 30px;
            }
            > div{
                display: inline-block;
                vertical-align: top;
                padding: 0 35px;
                width: 50%;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                div.members{
                    padding: 60px 60px 30px 60px;
                    background: #f7f7f7;
                    -webkit-box-shadow: 0px 0px 60px 0px rgba(48, 41, 31, 0.1);
                    -moz-box-shadow: 0px 0px 60px 0px rgba(48, 41, 31, 0.1);
                    box-shadow: 0px 0px 60px 0px rgba(48, 41, 31, 0.1);
                    @media only screen and (max-width: 991px) {
                        padding: 30px;
                        margin-bottom: 15px;
                    }
                    .members_list{
                        ul{
                            li{
                                display: inline-block;
                                vertical-align: top;
                                width: 50%;
                                margin-bottom: 30px;
                                > div{
                                    display: inline-block;
                                    vertical-align: middle;
                                    @media only screen and (max-width: 991px) {
                                        display: block;
                                    }
                                    &.image{
                                        height: 85px;
                                        width: 77px;
                                        position: relative;
                                        @include bg_pos;

                                        z-index: 2;
                                        margin: 0 auto 10px auto;
                                        > div{
                                            @include fill;
                                            @include bg_pos;
                                            background-size: cover;
                                            &.cover{
                                                z-index: 2;
                                                background-image: url('./../img/shield.png');
                                            }
                                        }
                                    }
                                    &.name{
                                        width: calc(100% - 77px);
                                        padding: 0 20px;
                                        font-size: 18px;
                                        line-height: 28px;
                                        font-family: $special_font;
                                        font-weight: 700;
                                        @media only screen and (max-width: 991px) {
                                            width: 100%;
                                            text-align: center;
                                        }
                                        p{
                                            font-weight: inherit;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .details{
                    font-family: $special_font;
                }
            }
        }
    }
}

.main_events{
    padding-bottom: 25px;
    .container{
        > .row{
            margin: 0 -20px;
            > div{
                padding: 0 20px;
            }
        }
    }
    .events_filters{
        background: $white;
        -webkit-box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
        -moz-box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
        box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
        padding:40px 30px;
        @media only screen and (max-width: 991px) {
            margin-bottom: 30px;
        }
        .filter_header{
            font-family: $special_font;
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 30px;
            h2{
                font-size: 21px;
                line-height: 31px;
            }
        }
        .selects{
            border-top: 1px solid #e8e8e8;
        }
        .chosen-container-single {
            text-align: left;
            &.chosen-container-active{
                .chosen-single{

                }
            }
            .chosen-single {
                border-radius: 0;
                background: transparent;
                border: none;
                box-shadow: none;
                border-bottom: 1px solid #e8e8e8;
                padding: 0;
                font-family: $special_font;
                color: $black;
                font-weight: 400;
                height: 44px;
                line-height: 44px;
                div{
                    b{
                        background-repeat: no-repeat;
                        background-position: center right;
                        background-image: url("./../img/select_arr2.png");
                    }
                }
            }
            .chosen-drop{
                background: $white;
                box-shadow: none;
                .active-result{
                    font-size: 12.5px;
                    font-family: $special_font;
                    &.highlighted{
                        background: $accent_color1;
                    }
                }
            }
        }
        .sub_btn{
            text-align: center;
            width: 100%;
            height: 55px;
            line-height: 55px;
            margin-top: 40px;
            background: $accent_color1;
            color: $white;
            font-family: $special_font;
            font-size: 12.5px;
            font-weight: 700;
            text-align: center;
            padding-left: 45px;

            border: none;
            position: relative;
            span{
                display: inline-block;
                padding-right: 34px;
                background-repeat: no-repeat;
                background-position: right center;
                background-image: url('./../img/sub_arr.png');
            }
        }
        .submit{
            @include fill;
            opacity: 0;
        }
    }
    .events{
        -webkit-box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
        -moz-box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
        box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
        .event_calendar{
            margin-bottom: 0;
        }
    }
}

.mycalendar2_slider {
    font-family: $special_font;
}

.mycalendar2_slider .slide .month .year{
    font-size: 10px;
}

.mycalendar2_slider .slide .month .mounth{
    font-size: 14px;
}

.mycalendar2_slider .slide .day .name{
    font-size: 9px;
}

.mycalendar2_slider .slide .day .no{
    font-size: 11px;
}

.events_table{
    margin-bottom: 75px;
    -webkit-box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
    -moz-box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
    box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
    @media only screen and (max-width: 991px) {
        width: 1200px;
        margin-bottom: 30px;
    }
    .events_table_header{
        height: 80px;
        line-height: 35px;
        font-size: 25px;
        text-transform: uppercase;
        font-family: $special_font;
        @include vertical;
        background: $white;
        padding: 0 40px;
    }
    .events_table_content{
        display: table;
        width: 100%;
        border-collapse: collapse;
        .events_table_row{
            display: table-row;
            &.events_header{
                background: $accent_color1;
                color: $white;
                font-family: $special_font;
                font-size: 12.5px;
                height: 55px;
                p{
                    font-weight: 700;
                }
            }&.events_sub_header{
                 background: #e26c71;
                 color: $white;
                 font-family: $special_font;
                 font-size: 12.5px;
                 height: 35px;
                 div{
                     border: none !important;
                 }
                 p{
                     font-weight: 700;
                 }
             }
            &.events_row{

                background: $white;
                border-bottom: 2px solid #e3e6e9;
                color: #5d6472;
                cursor: pointer;
                > div{
                    height: 70px;
                }
                span{
                    color: #3575ff;
                }
                .events_table_cell{
                    p{
                        font-weight: 600;
                    }
                }
                &:nth-child(even){
                    background: #f4f4f4;
                }
            }
            .events_table_cell{
                display: table-cell;
                vertical-align: middle;
                padding: 0 0 0 24px;
                .events_table_content{
                    font-size: 12.5px;
                    height: 100%;
                    box-shadow: none;
                    > .events_table_row{
                        border-bottom: 1px solid #e3e6e9;
                        border-left: 1px solid #e3e6e9;
                        border-right: 1px solid #e3e6e9;
                        &:last-child{
                            border-bottom: none;
                        }
                        .events_table_cell{
                            padding: 0 0 0 17px;
                            &.iw1{
                                width: 80px;
                            }
                            &.iw2{
                                width: 80px;
                            }
                            &.iw3{
                                width: 80px;
                            }
                            &.iw4{
                                width: 100px;
                            }
                            &.iw5{
                                width: auto;
                            }
                        }
                    }
                }
                &.w1{
                    width: 90px;
                }
                &.w2{
                    width: 120px;
                }
                &.w3{
                    width: 190px;
                }
                &.w4{
                    width: 120px;
                }
                &.w5{
                    width: 90px;
                }
                &.w6{
                    width: auto;
                    padding-left: 41px;
                }
                &.w7{
                    width: 170px;
                }
                &.w8{
                    width: 120px
                }
            }
        }
        .status{
            width: 40px;
            div{
                display: inline-block;
                vertical-align: top;
                color: #dbdee2;
                width: 18px;
                height: 18px;
                border: 1px solid #dbdee2;
                text-align: center;
                line-height: 18px;
                text-transform: uppercase;
                font-weight: 600;
                margin-top: 2px;
                margin-bottom: 2px;
                &.active{
                    color: #3562cd;
                    border: 1px solid #3562cd;
                }
                &:nth-child(odd){
                    margin-right: 4px;
                }
            }
        }
        ul{
            li{
                display: inline-block;
                vertical-align: middle;
                margin-right: 25px;
                @media only screen and (max-width: 1200px) {
                    margin-right: 5px;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    .minor_events_table_header{
        text-align: center;
        background: $accent_color1;
        color: $white;
        font-family: $special_font;
        font-size: 12.5px;
        height: 35px;
        div{
            border: none !important;
        }
        p{
            font-weight: 700;
        }
    }
    .minor_table{

        .events_header{
            background: $accent_color1;
            color: $white;
            font-family: $special_font;
            font-size: 12.5px;
            height: 55px;
            line-height: 55px;
            padding-left: 35%;
            &.as_row{
                padding-left: 0;
                > div{
                    width: 33%;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
            p{
                font-weight: 700;
                padding-left: 40px;
            }
        }
        .row_box{
            border-bottom: 2px solid #e3e6e9;
            &:nth-child(odd){
                background: #f4f4f4;
            }
            &:nth-child(even){
                background: $white;

            }
            div{
                display: inline-block;
                vertical-align: middle;
                padding:15px 40px;
                color: #5d6472;
                &:first-child{
                    width: 35%;
                }
                &:last-child{
                    width: 65%;
                    font-size: 12.5px;
                    line-height: 19px;
                }
            }
        }
        &.triple{
            .row_box{
                > div{
                    width: 33%;
                    &.flag_holders{
                        ul{
                            li{
                                p{
                                    padding-left: 26px;
                                    background-repeat: no-repeat;
                                    background-position: left center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

.row{
    &.small{
        margin: 0 -35.5px;
        @media only screen and (max-width: 991px) {
            margin: 0 -15px;
        }
        > div{
            padding: 0 35.5px;
            @media only screen and (max-width: 991px) {
                padding: 0 15px;
            }
        }
    }
}

.event_details_details{
    padding-bottom: 80px;
    @media only screen and (max-width: 991px) {
        padding-bottom: 50px;
    }
    .btns{
        text-align: center;
        padding-bottom: 60px;
        @media only screen and (max-width: 991px) {
            padding-bottom: 30px;
        }
        ul{
            li{
                display: inline-block;
                vertical-align: top;
                margin-right: 20px;
                &.active{
                    div{
                        p{
                            background: $accent_color1;
                            color: $white;
                        }
                    }
                }
                @media only screen and (max-width: 991px) {
                    margin-right: 0;
                    width: 100%;
                    margin-bottom: 15px;
                }
                div{
                    p{
                        cursor: pointer;
                        display: inline-block;
                        font-family: $special_font;
                        height: 40px;
                        line-height: 40px;
                        padding: 0 42px;
                        color: $black;
                        font-size: 13.5px;
                        font-weight: 700;
                        background: $white;
                        -webkit-box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
                        -moz-box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
                        box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);

                    }
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    .toggles1{
        > ul{
            > li{
                display: none;
                &.scores_page{
                    @media only screen and (max-width: 991px) {
                        overflow-x: scroll;
                    }
                }
            }
        }
    }
    .scrores_{
        -webkit-box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
        -moz-box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
        box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
    }
    .scores{

        background: $white;
        padding: 50px;
        @media only screen and (max-width: 991px) {
            padding: 30px;
        }
        .winners{
            font-family: $special_font;
            text-align: center;
            ul{
                @media only screen and (max-width: 991px) {
                    margin: 0 -10px;
                }
                li{
                    display: inline-block;
                    vertical-align: top;
                    width: 130px;
                    margin-right: 150px;
                    @media only screen and (max-width: 991px) {
                        margin-right: 0;
                        padding: 0 10px;
                        margin-bottom: 15px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                    div{
                        &.flag{
                            @include bg_pos;
                            background-size: cover;
                            height: 0;
                            padding-bottom: 69%;
                        }
                        &.who{
                            font-size: 25px;
                            margin-top: -12px;
                            p{
                                letter-spacing: 1px;
                            }
                            .place{
                                text-align: center;
                                border: 4px solid #dcdcdc;
                                border-radius: 50%;
                                width: 58px;
                                height: 58px;
                                line-height: 52px;
                                background: $white;
                                color: $accent_color1;
                                font-size: 30px;
                                font-weight: 700;
                                margin: 0 auto 20px auto;
                                -webkit-box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
                                -moz-box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
                                box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
                            }
                        }
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.scores_table{
    display: table;
    width: 100%;
    border-collapse: collapse;
    font-size: 12.5px;
    line-height: 33px;
    @media only screen and (max-width: 991px) {
        width: 1000px;
    }
    p{
        font-weight: 700;
    }
    .scores_table_row{
        display: table-row;
        &.score_header{
            background: $accent_color1;
            height: 55px;
            color: $white;
        }
        &.score_content{
            font-family: $main_font;
            color: #5d6472;
            border-bottom: 2px solid #e3e6e9;
            > div{
                padding: 12px 0 12px 25px;
            }
            &:nth-child(odd){
                background: #f4f4f4;
            }
            &:nth-child(even){
                background: $white;
            }
        }
        .scores_table_cell{
            display: table-cell;
            padding-left: 25px;
            vertical-align: middle;
            .flag{
                p{
                    padding-left: 25px;
                    background-position: left center;
                    background-repeat: no-repeat;
                    text-transform: uppercase;
                }
            }
            &.w1{
                width: 90px;
            }
            &.w2{
                width: auto;
            }
            &.w3{
                width: 145px;
            }
            &.w4{
                width: 130px;

            }
            &.w5{
                width: 105px;
            }
            &.w6{
                width: 105px;
            }
            &.w7{
                width: 105px;
            }
            &.w8{
                width: 205px;
            }
            &.w9{
                width: 205px;
            }
        }
    }
}

.members{

    div{
        &.row{
            margin-bottom: 60px;
            @media only screen and (max-width: 991px) {
                margin-bottom: 30px;
            }
        }
        &.major_member{

            .row{
                margin: 0 -30px;
                > div{
                    padding: 0 30px;
                }
            }
            div.image{
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                border-radius: 50%;
                border: 2px solid $white;
                position: relative;
                overflow: hidden;
                @media only screen and (max-width: 991px) {
                    width: 220px;
                    padding-bottom: 220px;
                    margin: 0 auto 20px auto;
                }
                div{
                    @include fill;
                    @include bg_pos;
                    background-size: cover;
                }
            }
            div.desc{
                header{
                    margin-bottom: 25px;
                    font-size: 14.5px;
                    h3{
                        color: $accent_color1;
                        margin-bottom: 10px;
                    }
                }
                .default_font{
                    color: $font_color;
                }
            }
        }
        &.member_list{
            text-align: center;
            ul{
                li{
                    padding: 0 15px;
                    width: 25%;
                    display: inline-block;
                    vertical-align: middle;
                    position: relative;
                    z-index: 2;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        margin-bottom: 30px;
                    }
                    @media only screen and (min-width: 991px) {
                        &:hover{
                            z-index: 10;
                            .member_top{
                                div.desc{
                                    h3, p{
                                        color: $white;
                                    }
                                }
                            }
                            .hidden_desc{
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }

                    .hidden_desc{
                        padding: 180px 30px 40px 30px;
                        background: $accent_color1;
                        color: $white;
                        position: absolute;
                        left: 0;
                        top: 60px;
                        width: 100%;
                        z-index: 1;
                        font-size: 12.5px;
                        line-height: 19px;
                        visibility: hidden;
                        opacity: 0;
                        @include anim;
                        @media only screen and (max-width: 991px) {
                            padding: 30px;
                            position: relative;
                            top: auto;
                            visibility: visible;
                            opacity: 1;
                            margin-top: 30px;
                        }
                    }
                    .member_top{
                        position: relative;
                        z-index: 2;
                        div.image{
                            width: 120px;
                            height: 120px;
                            position: relative;
                            border-radius: 50%;
                            overflow: hidden;
                            border: 2px solid $white;
                            margin: 0 auto 25px auto;
                            @media only screen and (max-width: 991px) {
                                width: 220px;
                                padding-bottom: 220px;
                            }
                            div{
                                @include fill;
                                @include bg_pos;
                                background-size: cover;
                                background-image: url('./../img/member_default.png');
                            }
                        }
                        div.desc{
                            text-align: center;
                            font-size: 14.5px;
                            line-height: 19px;


                            h3{
                                font-size: 18px;
                                margin-bottom: 10px;
                                @include anim;
                            }
                            p{
                                color: #7e7e7e;
                                @include anim;
                            }
                        }
                    }
                }
            }
        }
    }
}

.structure{
    padding-bottom: 80px;
    @media only screen and (max-width: 991px) {
        overflow-y: scroll;
       .container{
           width: 1200px;
       }
    }
    .left_list{
        position: relative;
        z-index: 3;
        > ul{
            > li{
                display: inline-block;
                vertical-align: top;
                width: calc(100% / 4);
            }
        }
    }
    .right_list{
        > ul{
            > li{
                display: inline-block;
                vertical-align: top;
                width: calc(100% / 2);
            }
        }
    }
    .structure_box{
        background: $white;
        display: inline-block;
        text-align: center;
        border: 1px solid #eaeaea;
        border-bottom: 3px solid #eaeaea;
        font-family: $special_font;
        cursor:pointer;

        a{
            color: black;
            @include anim;
            &:hover{
                color:$accent_color1;
                text-decoration:none;
            }
        }
        div{
            height: 100%;
            @include vertical;
            h2,h3{
                width: 100%;
            }
        }
        &.large{
            width: 400px;
            height: 72px;
            h2{
                line-height: 38px;
            }
        }
        &.medium{
            width: 270px;
            height: 60px;
            h3{
                line-height: 22px;
                font-size: 14.5px;
            }
        }
        &.small{
            width: 215px;
            height: 60px;
            @media only screen and (max-width: 1500px) {
                width: 100%;
            }
            h3{
                line-height: 22px;
                font-size: 14.5px;
            }
        }
        &.inner{
            width: 215px;
            height: 50px;
            font-size: 12.5px;
            border-top: none;
            background: #f1f1f1;
            text-align: left;
            @media only screen and (max-width: 1500px) {
                width: 100%;
                font-size: 10.5px;
            }
            @media only screen and (max-width: 1200px) {
                font-size: 9px;
            }
            div{
                padding: 0 18px;
            }
            p{
                font-weight: 600;
            }
        }
    }
    .last_main_category{
        position: relative;
    }
}

.rwd_menu{
    position: fixed;
    height: 100vh;
    width: 100%;
    background: $theme_color3;
    top: 0;
    left: 0;
    color: $white;
    z-index: 9999;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 20px 15px 0 15px;
    display: none;
    .close_menu{
        width: 30px;
        position: relative;
        height: 30px;
        margin-left: auto;
        margin-bottom: 15px;
        background: $accent_color1;
        span{
            width: 80%;
            height: 3px;
            background: $white;
            display: block;
            position: absolute;
            left: 10%;
            &:nth-child(1){
                top: 13px;
                transform:rotate(45deg);
            }
            &:nth-child(2){
                left: 100%;
                opacity: 0;
            }
            &:nth-child(3){
                top: 13px;
                transform:rotate(-45deg);
            }
        }
    }
    .menu_box{
        nav{
            > ul{
                > li{
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                    border-bottom: 1px solid $font_color;
                    > ul{
                        padding-left: 15px;
                    }
                }
            }
        }
        a{
            color: $white;
        }
        li{
            position: relative;
            ul{
                display: none;
            }
            div{
                min-height: 30px;
                line-height: inherit;
                border-bottom: $white;

                &.parent_btn{
                    width: 30px;
                    background: $accent_color1;
                    position: absolute;
                    top: 0;
                    right: 0;
                    @include vertical;
                    &.open{
                        span{
                            &:nth-child(1){
                                top: 13px;
                                transform:rotate(45deg);
                            }
                            &:nth-child(2){
                                left: 100%;
                                opacity: 0;
                            }
                            &:nth-child(3){
                                top: 13px;
                                transform:rotate(-45deg);
                            }
                        }
                    }
                    span{
                        width: 80%;
                        height: 3px;
                        background: $white;
                        display: block;
                        position: absolute;
                        left: 10%;
                        @include anim;
                        &:nth-child(1){
                            top: 5px;
                        }
                        &:nth-child(2){
                            top: 13px;
                        }
                        &:nth-child(3){
                            top: 22px;
                        }
                    }
                }
            }
        }
    }
    .social_box{
        padding-top: 15px;
        > ul{
            > li{
                display: inline-block;
                vertical-align: top;
                width: 25%;
                ul{
                    padding-top: 10px;
                }
                a{
                    font-size: 10px;
                    line-height: 13px;
                    color: $font_color;
                }
            }
        }
    }
}

.overflow_content{
    overflow-y: scroll;
}

.news{
    .list_holder{
        > div{
            display: none;
        }
    }
}

.social_carousels{
    .social_carousel_content{
        > div{
            //display: none;
        }
    }
}

body{
    &.contrast{
        .category {
            p{
                background: transparent !important;
            }
        }
        div{
            color: red !important;
        }
        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $black !important;
        }
        ::-moz-placeholder { /* Firefox 19+ */
            color: $black !important;
        }
        :-ms-input-placeholder { /* IE 10+ */
            color: $black !important;
        }
        :-moz-placeholder { /* Firefox 18- */
            color: $black !important;
        }
        input, textarea{
            background: yellow !important;
            color: $black !important;
        }
        .more_btn2{
            background: black !important;
            color:  white !important;
        }
        .arr{
            display: block !important;
        }
        *{
            border: none !important;
            &:after{
                border: none !important;
            }
        }
        img {
            color: green;
            font-size: 11px;
            color: red;
            white-space: nowrap;
        }
        a{
            color: yellow !important;
        }
        h1,h2,h3,h4,h5,h6,p{
            color: red !important;
        }
        .page_header{
            background: $black;
        }
        .sections, .photo_galery, .social_media{
            background: $black;
        }
        .image, div, section, li, p, article{
            background: $black !important;
        }
    }
}

.tiles{
    padding-bottom: 50px;
    @media only screen and (max-width: 1500px) {
        padding-bottom: 20px;
    }
    @media only screen and (max-width: 991px) {
        padding-bottom: 0;
    }
    .tiles_list{
        text-align: center;
        padding: 0 100px;
        @media only screen and (max-width: 1200px) {
            padding: 0;
        }
        ul{
            margin: 0 -15px;
            li{
                display: inline-block;
                vertical-align: top;
                width: 33.33%;
                padding: 0 15px;
                margin-bottom: 30px;
                @include anim;
                &:hover{
                    transform: scale(1.05);
                    a{
                        text-decoration: none;
                    }
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                article{
                    -webkit-box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
                    -moz-box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
                    box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
                    background: rgba(255,255,255,0.8);
                    height: 200px;
                    width: 100%;
                    font-size: 14px;
                    font-weight: bold;
                    color: $black;
                    text-align: center;
                    border-radius: 10px;
                    position: relative;
                    overflow: hidden;
                    padding-top: 20px;
                    div.image{
                        @include bg_pos;
                        background-size: contain;
                        width: 230px;
                        height: 100px;
                        margin: 0 auto;
                        margin-bottom: 5px;
                    }
                    div.desc{
                        width: 100%;
                        text-align: center;
                        padding: 0 30px;
                        h3{
                            font-size: 25px;
                            line-height: 30px;
                            font-weight: 700;
                            text-transform: uppercase;
                            @media only screen and (max-width: 1500px) {
                                font-size: 20px;
                                line-height: 25px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.cadrelist{
    cursor: pointer;
}

.search_results{
    padding-bottom: 50px;
    @media only screen and (max-width: 991px) {
        padding-bottom: 0;
    }
    ul{
        li{
            width: 100%;
            margin-bottom: 30px;
            a{
                font-size: 18px;
                line-height: 28px;
                display: block;
                background: $white;
                border-radius: 5px;
                @include anim;
                color: $font_color;
                padding: 15px 20px;
                display: block;
                font-weight:700;
                border-bottom: 2px solid $theme_color1;
                .nav_info{
                    color: $font_color;
                    padding-top: 10px;
                    font-size: 12px;
                    line-height: 16px;
                    @include anim;
                    .small_date{
                        padding-top: 10px;
                    }
                }
                @media only screen and (max-width: 991px) {
                    font-size: 14px;
                    line-height: 24px;
                }
                &:hover{
                    text-decoration: none;
                    transform: scale(1.02);
                    -webkit-box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
                    -moz-box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
                    box-shadow: 0px 0px 16px 0px rgba(48,41,31,0.2);
                    color:$white;
                    background: $accent_color1;
                    .nav_info{
                        color:$white
                    }
                }
            }
        }
    }
}

.link0{
    background-color: $white;
    border: 1px solid #eaeaea;
    border-bottom: 3px solid #eaeaea;
    padding: 0 70px 0 20px;
    background-repeat: no-repeat;
    transition: 0.3s all ease;
    display: inline-block;
    margin: 10px 0;
    color: $black;
    height: 60px;
    line-height: 60px;
    font-family: $special_font;
    background-repeat: no-repeat;
    background-position: right 30px center;
    background-image: url(./../img/more_arr.png);
    font-weight: 500;
    @include anim;
    &:hover{
        color: $white;
        background-color: $accent_color1;
        text-decoration: none;
        background-image: url(./../img/more_arr_white.png);
    }
}

.site_map{
    padding-bottom: 60px;
    @media only screen and (max-width: 991px) {
        padding-bottom: 30px;
    }
    a{
        @include anim;
        &:hover{
            text-decoration: none;
            color: $accent_color1;
        }
    }
    > ul{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
        grid-gap: 25px;
        grid-auto-flow: dense;
        padding: 10px;
        @media only screen and (max-width: 1200px) {
            grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
        }
        @media only screen and (max-width: 991px) {
            grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
        }
        > li{
            -webkit-box-shadow: 0px 0px 16px 0px rgba(48, 41, 31, 0.2);
            -moz-box-shadow: 0px 0px 16px 0px rgba(48, 41, 31, 0.2);
            box-shadow: 0px 0px 16px 0px rgba(48, 41, 31, 0.2);
            padding: 30px;
            background: $white;
            grid-column-end: span 1;
            grid-row-end: span 1;
            > div{
                a{
                    color: $black;
                    font-size: 30px;
                    line-height: 40px;
                    font-weight: 700;
                }
            }
            > ul{
                padding-top: 20px;
                > li{
                    div{
                        a{
                            font-size: 14px;
                            line-height: 30px;
                            color: $font_color;
                            font-weight: 700;
                            @include anim;
                            &:hover{
                                padding-left: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}


.error{
    text-align: center;
    min-height: 84vh;
    @include vertical;
    &.error_404{
        .section_header{
            background-image:url("./../img/err_404.png");
        }
    }
    &.error_500{
        .section_header{
            background-image:url("./../img/err_500.png");
        }
    }
    .section_header{
        background-position: center top;
        background-repeat: no-repeat;
        padding-top: 190px;
        @media only screen and (max-width: 1500px) {
            padding-top: 170px;
        }
        .default_font{
            h1{
                font-size: 62px;
                line-height: 75px;
                @media only screen and (max-width: 1500px) {
                    font-size: 30px;
                    line-height: 43px;
                }
                @media only screen and (max-width: 991px) {
                    font-size: 22px;
                    line-height: 35px;
                }
            }
        }
    }
    .desc{
        font-size: 18px;
        line-height: 28px;
        color:#5d6472;
        margin: 50px 0;
        @media only screen and (max-width: 1500px) {
            font-size: 14px;
            line-height: 24px;
            margin: 30px 0;
        }
    }
    .back_btn1{
        padding: 0 40px;
        line-height: 50px;
        background: $accent_color1;
        font-size: 12.5px;
        color: $white;
        font-weight: 700;
        display: inline-block;
        &:hover{
            text-decoration: none;
        }
        span{
            padding-left: 30px;
            background-position: left center;
            background-repeat: no-repeat;
            background-image: url("./../img/back_arr.png");
        }
    }
}

.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.mycalendar2_slider .slide .day{
    padding: 15px 0 !important;
}

.fis{
    margin-top: 170px;
    @media only screen and (max-width: 991px) {
        margin-left: 700px;
    }
}

.officefielddescription{
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 5px;
    color: #333333;
    font-size:12px;
}

.news_category p{
    display: none !important;
}

.news_category p.important{
    display: block !important;
}

.mobile_img{
    display: none;
    @media only screen and (max-width: 991px) {
        display: block;
        max-width: 100%;
        height: auto;
    }
}

.btns_list{
    > ul{
        text-align: center;
        > li{
            display: inline-block;
            vertical-align: top;
            padding: 0 15px;
            width: 25%;
            margin-bottom: 30px;
            a{
                width: 100%;
                text-align: left;
            }
            @media only screen and (max-width: 991px) {
                width: 100%;
                text-align: left;
                a{
                    width:100%
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .twitter_box {
        &.twitter-block {
            margin-top: 50px;
            height: 590px;
        }
    }
}
